import React, { useState } from "react";
import "./Login.css";
import bgImg from "../../../images/background.png";
import logo from "../../../images/logo.png";
import user from "../../../images/user.png";
import pwIcon from "../../../images/passw.png";
import axios from 'axios';
import { useNavigate, Link } from "react-router-dom";
import { baseURL } from '../../../config/config';

const Login = ({ onLoginSuccess }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const validateEmail = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.match(emailPattern)) {
      setEmailError("Username does not exist");
      return false;
    }
    setEmailError("");
    return true;
  };

  const validatePassword = () => {
    if (password.length < 6) {
      setPasswordError("Incorrect Password");
      return false;
    }
    setPasswordError("");
    return true;
  };

  const errorStyle = {
    color: "#DF0000",
    fontSize: "12px",
    display: "flex",
    gap: "2px",
    justifyContent: "left",
    alignItems: "center",
  };

  const containerStyle = {
    backgroundImage: `url(${bgImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100vh",
    display: "flex",
  };

  const rightColumnStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
    padding: "2rem",
    backgroundColor: "#F8F8F8",
    borderRadius: "50px 0 0 50px",
    boxShadow: "3px 4px 31px rgba(0, 0, 0, 0.1)",
  };

  const inputGroupStyle = {
    position: "relative",
    width: "100%",
  };

  const inputStyle = {
    boxSizing: "border-box",
    width: "100%",
    height: "38px",
    background: "#FFFFFF",
    border: "1px solid #E7E8E9",
    borderRadius: "8px",
    paddingLeft: "40px",
    fontSize: "15px",
    lineHeight: "19px",
    color: "#535353",
  };

  const iconStyle = {
    position: "absolute",
    left: "12px",
    top: "50%",
    transform: "translateY(-50%)",
    color: "#BCBCBC",
    fontSize: "24px",
  };
  const inputErrorStyle = {
    ...inputStyle,
    border: "1px solid #DF0000",
  };

  


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateEmail() && validatePassword()) {
      try {
        const response = await axios.post(`${baseURL}/login`, {
          email,
          password,
        });

        if (response.status === 200) {
          console.log("Login successful:", response.data);
          navigate('/servicePageOne'); // Redirect to next page on success
        } else {
          console.error("Login error:", response.data);
          if (response.data.message === "Incorrect password") {
            setPasswordError("Incorrect Password");
          } else if (response.data.message === "User not found") {
            setEmailError("User not found");
          }
        }
      } catch (error) {
        console.error("Login error:", error);
        setEmailError("An error occurred, please try again");
      }
    }
  }

  return (
    <div className="login-screen" style={containerStyle}>
      <div className="right-column" style={rightColumnStyle}>
        <div className="text-center">
          <div className="logo-container">
            <div>
              <img src={logo} alt="logo" />
            </div>
            <div className="logo-text">
              Logo <br /> Maker
            </div>
          </div>
          <div className="welcome-text">Welcome Logo Maker Portal</div>
          <div className="login-text">
            <p>Login to your account</p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="input-group" style={inputGroupStyle}>
              <img src={user} alt="User icon" style={iconStyle} />
              <input
                type="email"
                placeholder="Username"
                value={email}
                onChange={handleEmailChange}
                onBlur={validateEmail}
                style={inputStyle}
              />
            </div>
            {emailError && (
              <div style={errorStyle}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M6 0C2.68352 0 0 2.68376 0 6C0 9.31648 2.68376 12 6 12C9.31648 12 12 9.31624 12 6C12 2.68352 9.31622 0 6 0ZM6 11.0625C3.20173 11.0625 0.9375 8.79809 0.9375 6C0.9375 3.20173 3.20191 0.9375 6 0.9375C8.79827 0.9375 11.0625 3.20191 11.0625 6C11.0625 8.79827 8.79806 11.0625 6 11.0625Z"
                    fill="#DF0000"
                  />
                  <path
                    d="M6 5.02344C5.74111 5.02344 5.53125 5.2333 5.53125 5.49219V8.51077C5.53125 8.76966 5.74111 8.97952 6 8.97952C6.25889 8.97952 6.46875 8.76964 6.46875 8.51075V5.49219C6.46875 5.2333 6.25889 5.02344 6 5.02344Z"
                    fill="#DF0000"
                  />
                  <path
                    d="M6 4.44922C6.34949 4.44922 6.63281 4.1659 6.63281 3.81641C6.63281 3.46691 6.34949 3.18359 6 3.18359C5.65051 3.18359 5.36719 3.46691 5.36719 3.81641C5.36719 4.1659 5.65051 4.44922 6 4.44922Z"
                    fill="#DF0000"
                  />
                </svg>
                {emailError}
              </div>
            )}
            <div className="input-group password-input" style={inputGroupStyle}>
              <img src={pwIcon} alt="Password icon" style={iconStyle} />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={handlePasswordChange}
                onBlur={validatePassword}
                style={passwordError ? inputErrorStyle : inputStyle}
              />
            </div>
            {passwordError && (
              <div style={errorStyle}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M6 0C2.68352 0 0 2.68376 0 6C0 9.31648 2.68376 12 6 12C9.31648 12 12 9.31624 12 6C12 2.68352 9.31622 0 6 0ZM6 11.0625C3.20173 11.0625 0.9375 8.79809 0.9375 6C0.9375 3.20173 3.20191 0.9375 6 0.9375C8.79827 0.9375 11.0625 3.20191 11.0625 6C11.0625 8.79827 8.79806 11.0625 6 11.0625Z"
                    fill="#DF0000"
                  />
                  <path
                    d="M6 5.02344C5.74111 5.02344 5.53125 5.2333 5.53125 5.49219V8.51077C5.53125 8.76966 5.74111 8.97952 6 8.97952C6.25889 8.97952 6.46875 8.76964 6.46875 8.51075V5.49219C6.46875 5.2333 6.25889 5.02344 6 5.02344Z"
                    fill="#DF0000"
                  />
                  <path
                    d="M6 4.44922C6.34949 4.44922 6.63281 4.1659 6.63281 3.81641C6.63281 3.46691 6.34949 3.18359 6 3.18359C5.65051 3.18359 5.36719 3.46691 5.36719 3.81641C5.36719 4.1659 5.65051 4.44922 6 4.44922Z"
                    fill="#DF0000"
                  />
                </svg>
                {passwordError}
              </div>
            )}
            <button type="submit" className="btn btn-primary login-button">
              Login
            </button>
            <Link to="/register" className="btn text-decoration-underline">
              Register
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
