import React, { useEffect, useState } from "react";
import editIcon from "../../../images/edit.svg";
import deleteIcon from "../../../images/bin.svg";
import bottomArrow from "../../../images/Group 40.1.png";
import downArrow from "../../../images/down 1.png";
import axios from "axios";
import { baseURL } from '../../../config/config';
import { useNavigate } from "react-router-dom";

import "./ServicePageOne.css";

const ServicePageOne = () => {
  const navigate = useNavigate();
  const [entries, setEntries] = useState([]);
  const [pageId, setPageId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
 

  useEffect(() => {
    const fetchEntries = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/enterMetas`
        );
        setEntries(response.data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchEntries();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error fetching data: {error.message}</div>;

  const formatDate = (dateString) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "N/A";
    }

    const month = months[date.getMonth()];
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    return `${month} ${day}, ${year}`;
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error fetching data: {error.message}</div>;


  const handleDelete = async (id) => {
    setError('');


    try {
      
      const response = await axios.delete(`${baseURL}/enterMetas/${id}`);
      alert("Page deleted successfully");
 

      setEntries(entries.filter(entry => entry.id !== id));
      
    } catch (error) {
      if (error.response) {
        // Handle server-side errors
        if (error.response.status === 404) {
          setError('Record not found. It may have already been deleted.');
        } else {
          setError('An error occurred while deleting the entry. Please try again.');
        }
      } else {
        // Handle network or other errors
        setError('An unexpected error occurred. Please check your network and try again.');
      }
    }
  };


  const handleEdit = (taskId) =>{
    navigate(`/enterMetas?taskId=${taskId}`);
  }

  return (
    <>
      <div className="container">
        <div className="d-flex justify-content-end align-items-center pt-5 pr-5 show-entries-container">
          <span className="show-text">show</span>
          <div className="d-flex align-items-center mx-2 next-button-container">
            <span className="entries-number">10</span>
            <img src={downArrow} alt="Bottom Arrow" className="bottom-arrow" />
          </div>
          <span className="entries-text">entries</span>
        </div>

        {/* Table Section */}
       
        <div className="table-section">
          <div className="table-content">
            <div className="table-header-wrapper">
              <table className="custom-table">
                <thead>
                  <tr>
                    <th>
                      <span>Date Added</span>
                      <img
                        src={bottomArrow}
                        alt="Bottom Arrow"
                        className="ml-5"
                      />
                    </th>
                    <th>URL</th>
                    <th>Type</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {entries.map((entry) => (
                    <tr key={entry.id}>
                      <td>{formatDate(entry.created_at)}</td>
                      {/* Assuming there's a date field */}
                      <td className="url-content">
                        <a
                          href={`https://staging.alldesignsstudio.com/services/${entry.service_page_url}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {entry.service_page_url}
                        </a>
                      </td>
                      <td>Service</td>{" "}
                      {/* Assuming 'Service' is a static value */}
                      <td>{entry.status || "Incomplete"}</td>{" "}
                      {/* Use entry.status if available */}
                      <td>
                        <button className="edit-button" style={{border:'none', backgroundColor:'transparent', marginRight:'0.8rem'}}>
                        <img 
                        src={editIcon} 
                        alt="Edit Icon" 
                        onClick={
                          () => {handleEdit(entry.id);
                          setPageId(entry.id);
                        }}
                        />
                        </button>
                        <button className="edit-button" style={{border:'none', backgroundColor:'transparent'}}>
                        <img 
                        src={deleteIcon} 
                        alt="Delete Icon" 
                        onClick={
                          () => {handleDelete(entry.id);
                          setPageId(entry.id);
                        }}
                        />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {error && <div className="error-message alert alert-danger">{error}</div>}
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicePageOne;
