import React, { useState, useEffect, useRef } from "react";
import deleteIcon from "../../../images/trash-2.svg";
import plus from "../../../images/plus3.svg";
import rightArrow from "../../../images/rightArrow.png";
import axios from 'axios';
import "./OurServicesPage.css";
import { baseURL } from '../../../config/config';

const SectionThree = ({setCompleted, taskId}) => {
  const [sections, setSections] = useState([]);
  const [heading, setHeading] = useState('');
  const [subHeading, setSubHeading] = useState('');
  const [stepHeading, setStepHeading] = useState('');
  const [stepName, setStepName] = useState('');
  const [stepDesc, setStepDesc] = useState('');
  const [stepButtonText, setStepButtonText] = useState('');
  const [stepButtonLink, setStepButtonLink] = useState('');
  const [iconFile, setIconFile] = useState(null);
  const [iconAltText, setIconAltText] = useState('');
  const fileInputRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isUpdateForm, setIsUpdateForm] = useState(false);


  useEffect(() => {
    if (taskId) {
      console.log("ParamId", taskId);
      axios.get(`${baseURL}/sectionThree/${taskId}`)
        .then(response => {
          console.log("response.data", response.data);
          const data = response.data;
  
          const hasData = data && data.steps && data.steps.length > 0;

          setCompleted(hasData);
          setIsUpdateForm(hasData);
  
        })
        .catch(error => {
          console.error('Error fetching record:', error);
        });
    }
  }, [taskId]);  


  useEffect(() => {
    console.log('sections', sections)
    console.log(`legthd`, sections.length);

  }, [sections]);

  const handleFileChange = (e) => {
    setIconFile(e.target.files[0]);
  };

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setIconFile(null);
  };


  const addSection = () => {
    if ((!heading || !stepHeading || !stepName || !stepDesc || !stepButtonText || !stepButtonLink || !iconFile || !iconAltText)) {
      setErrorMessage('All fields are required.');
      return;
    }
    const newSection = {
      id: Date.now(),
      stepHeading,
      stepName,
      stepDesc,
      stepButtonText,
      stepButtonLink,
      iconFile,
      iconAltText,
    };

    setSections((prevSections) => {
      // Check if the new section already exists to prevent duplicates
      const sectionExists = prevSections.some((section) =>
        section.stepHeading === newSection.stepHeading &&
        section.stepName === newSection.stepName &&
        section.stepDesc === newSection.stepDesc &&
        section.stepButtonText === newSection.stepButtonText &&
        section.stepButtonLink === newSection.stepButtonLink &&
        section.iconAltText === newSection.iconAltText &&
        section.iconFile === newSection.iconFile
      );
      if (sectionExists) return prevSections;

      return [...prevSections, newSection];
    });

    // Clear form fields
    setStepHeading('');
    setStepName('');
    setStepDesc('');
    setStepButtonText('');
    setStepButtonLink('');
    resetFileInput();
    setIconAltText('');
  };

  const deleteSection = (id) => {
    setSections(sections.filter((section) => section.id !== id));
  };

  const handleInputChange = (id, event) => {
    const newSections = sections.map((section) => {
      if (section.id === id) {
        return { ...section, name: event.target.value };
      }
      return section;
    });
    setSections(newSections);
  };


  const handleSaveSection = async (event) => {
    event.preventDefault();
    if (sections.length < 4) {
      setErrorMessage('Steps must contain at least 4 items.');
      return;
    }

    // If validation passes, clear the error message and proceed with form submission
    setErrorMessage('');


    // Add the new section if necessary
    if (stepHeading !== '' || stepName !== '' || iconFile !== null || iconAltText !== '' || !stepButtonText || !stepButtonLink) {
      addSection(); // Ensure the section is added
    }

    // Delay to ensure state update is reflected
    await new Promise((resolve) => setTimeout(resolve, 100));

    // Use callback form of setSections to get updated sections
    const serviceUrl = sessionStorage.getItem('servicePageUrl');
    const serviceId = sessionStorage.getItem('serviceId');
    const formData = new FormData();
    formData.append('heading', heading);
    formData.append('subheading', subHeading);
    formData.append('slug', serviceUrl);
    formData.append('service_id', serviceId);

    // Add sections data to formData
    if (sections.length === 0) {
      // If sections is empty, directly append form field values
      if (stepHeading !== '' || stepName !== '' || iconFile !== null || iconAltText !== '' || !stepButtonText || !stepButtonLink || !stepDesc) {
        formData.append('benefitsData[0][heading]', stepName);
        formData.append('benefitsData[0][subheading]', stepHeading);
        formData.append('benefitsData[0][description]', stepDesc);
        formData.append('benefitsData[0][button_text]', stepButtonText);
        formData.append('benefitsData[0][button_link]', stepButtonLink);
        formData.append('benefitsData[0][icon_alt_text]', iconAltText);
        if (iconFile) {
          formData.append('icon_path', iconFile); // Each file with unique key
        }
      }
    } else {
      // If sections is not empty, add sections data to formData
      sections.forEach((section, index) => {
        formData.append(`stepsData[${index}][heading]`, section.stepName);
        formData.append(`stepsData[${index}][subheading]`, section.stepHeading);
        formData.append(`stepsData[${index}][description]`, section.stepDesc);
        formData.append(`stepsData[${index}][button_text]`, section.stepButtonText);
        formData.append(`stepsData[${index}][button_link]`, section.stepButtonLink);
        formData.append(`stepsData[${index}][icon_alt_text]`, section.iconAltText);
        if (section.iconFile) {
          formData.append(`icon_path`, section.iconFile); // Each file with unique key
        }
      });
    }


    // Log FormData contents
    for (const [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    try {
      let response;
      if(isUpdateForm === false){
      response = await fetch(`${baseURL}/sectionThree`, {
        method: 'POST', // Change to PUT if backend expects PUT
        body: formData,
      });
    }
    else{
      response = await fetch(`${baseURL}/sectionThree/${taskId}`, {
        method: 'PUT', // Change to PUT if backend expects PUT
        body: formData,
      });
    }
      if (response.ok) {
        alert('Section saved successfully');
        console.log('Section saved successfully');
        // Reset form and states

        setHeading('');
        setSubHeading('');
        setIconFile(null);
        setStepHeading('');
        setStepName('');
        setStepDesc('');
        setStepButtonText('');
        setStepButtonLink('');
        resetFileInput();
        setIconAltText('');
        setSections([]);
      } else {
        console.error('Error saving section:', response.statusText);
      }
    } catch (error) {
      console.error('Error saving section:', error);
    } finally {
      // setIsProcessing(false);
      // setIsSaving(false);
    }
  };



  return (
    <div>
      <div className="faq-answer-content">
        <form onSubmit={handleSaveSection} style={{ width: "98%, margin: auto" }} onClick={(e) => e.stopPropagation()}>
          <div className="form-group" onClick={(e) => e.stopPropagation()}>
            <label htmlFor="Heading">Heading</label>
            <div
              className="styled-input-field"
              style={{
                width: "1101px",
                height: "50px",
              }}
            >
              <input
                type="text"
                placeholder="Enter Heading"
                className="input-text"
                value={heading}
                onChange={(e) => setHeading(e.target.value)}
              />
              <div className="word-count-input">0/60</div>
            </div>
          </div>

          <div className="form-group" onClick={(e) => e.stopPropagation()}>
            <label htmlFor="subHeading">Subheadline</label>
            <div
              className="styled-input-field"
              style={{
                width: "1101px",
                height: "50px",
              }}
            >
              <input
                type="text"
                id="subHeading"
                placeholder="Enter Subheadline"
                className="input-text"
                value={subHeading}
                onChange={(e) => setSubHeading(e.target.value)}
              />
              <div className="word-count-input">0/60</div>
            </div>
          </div>

          {sections.map((section, index) => (
            <div key={section.id}>
              <div
                className="form-group row"
                onClick={(e) => e.stopPropagation()}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "1px solid #e7e8e9",
                  width: "1064px",
                  marginLeft: "4px",
                  borderRadius: "10px",
                  padding: "10px",
                }}
              >

                <div>
                  <p className="font-weight-bold small" style={{ color: 'rgba(203, 0, 100, 1)' }}>
                    Step {section.benefitNumber}
                  </p>
                  <p className="font-weight-bold p">{section.stepHeading}</p>
                </div>
                <div>
                  <img
                    src={deleteIcon}
                    alt="Delete Icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteSection(section.id);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            </div>
          ))}

          {sections.length <= 3 && (
            <>
              <div
                className="d-flex justify-content-between align-items-center col-sm-12 mt-2"
                style={{ width: "1050px", height: "30px" }}
              >
                <div
                  className="d-flex justify-content-between align-items-center col-sm-12 "
                  style={{ width: "1100px", height: "30px" }}
                >
                  <span className="font-weight-bold">
                    Steps({sections.length + 1}/4)
                  </span>
                </div>
                <div className="d-flex mr-2">
                  <img
                    src={deleteIcon}
                    alt="delete Icon"
                    onClick={(e) => e.stopPropagation()}
                  />
                  <img
                    src={plus}
                    alt="delete Icon"
                    onClick={(e) => {
                      addSection();
                      e.stopPropagation();
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>

              <div
                className="form-group row p-3"
                onClick={(e) => e.stopPropagation()}
              >
                <label className="col-sm-1 col-form-label">Name</label>
                <div
                  className="styled-input-field col-sm-11"
                  style={{
                    width: "1101px",
                    height: "50px",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Enter Step Name"
                    className="input-text"
                    value={stepName}
                    onChange={(e) => setStepName(e.target.value)}
                  />
                  <div className="word-count-input-half">0/30</div>
                </div>
              </div>

              <div
                className="form-group p-3 row"
                onClick={(e) => e.stopPropagation()}
              >
                <label className="col-sm-1 col-form-label">Heading</label>
                <div
                  className="styled-input-field col-sm-11"
                  style={{
                    width: "1101px",
                    height: "50px",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Enter Heading"
                    className="input-text"
                    value={stepHeading}
                    onChange={(e) => setStepHeading(e.target.value)}
                  />
                  <div className="word-count-input-half">0/30</div>
                </div>
              </div>

              <div
                className="form-group p-3 row"
                onClick={(e) => e.stopPropagation()}
              >
                <label className="col-sm-1 col-form-label">Description</label>
                <div
                  className="styled-input-field col-sm-11"
                  style={{
                    width: "1101px",
                    height: "150px",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Enter Description"
                    className="input-text"
                    maxLength={120}
                    value={stepDesc}
                    onChange={(e) => setStepDesc(e.target.value)}
                  />
                  <div className="word-count-input-half">0/120</div>
                </div>
              </div>

              <div
                className="form-group p-3 row"
                onClick={(e) => e.stopPropagation()}
              >
                <label className="col-sm-1 col-form-label">Icon</label>
                <div
                  className="styled-input-field col-sm-11"
                  style={{
                    width: "1101px",
                    height: "50px",
                  }}
                >
                  <input
                    type="file"
                    placeholder="Choice Icon"
                    className="input-text"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                  />
                </div>
              </div>
              <div
                className="form-group p-3 row"
                onClick={(e) => e.stopPropagation()}
              >
                <label className="col-sm-1 col-form-label">Alt Text</label>
                <div
                  className="styled-input-field col-sm-11"
                  style={{
                    width: "1101px",
                    height: "50px",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Enter Alt Text"
                    className="input-text"
                    value={iconAltText}
                    onChange={(e) => setIconAltText(e.target.value)}
                  />
                </div>
              </div>
              <div
                className="form-group p-3 row"
                onClick={(e) => e.stopPropagation()}
              >
                <label className="col-sm-1 col-form-label">Button Text</label>
                <div
                  className="styled-input-field col-sm-11"
                  style={{
                    width: "1101px",
                    height: "50px",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Enter Icon Alt Text"
                    className="input-text"
                    value={stepButtonText}
                    onChange={(e) => setStepButtonText(e.target.value)}
                  />
                  <div className="word-count-input-half">0/30</div>
                </div>
              </div>
              <div
                className="form-group p-3 row"
                onClick={(e) => e.stopPropagation()}
              >
                <label className="col-sm-1 col-form-label">Button Link</label>
                <div
                  className="styled-input-field col-sm-11"
                  style={{
                    width: "1101px",
                    height: "50px",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Enter Enter Button Link"
                    className="input-text"
                    value={stepButtonLink}
                    onChange={(e) => setStepButtonLink(e.target.value)}
                  />
                </div>
              </div>
            </>
          )}
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          <div
            className="p-3"
            style={{
              width: "151px",
              height: "40px",
              marginLeft: "20px",
              borderRadius: "5px",
              background: "Linear-gradient(90deg, #9300B8 0%, #CB0064 100%)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button type="submit"
              style={{
                color: "#FFF",
                fontSize: "17px",
                fontWeight: "700",
                lineHeight: "normal",
                border: "none",
                background: "transparent",
              }}
            >
              Save Section
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default SectionThree
