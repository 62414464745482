import React, { useState } from "react";
import deleteIcon from "../../../images/trash-2.svg";
import plus from "../../../images/plus3.svg";
import "./OurServicesPage.css";

const Interlinkings = () => {
  const [sections, setSections] = useState([]);

  const addSection = () => {
    setSections([
      ...sections,
      { id: Date.now(), benefitNumber: sections.length + 1 },
    ]);
  };

  const deleteSection = (id) => {
    setSections(sections.filter((section) => section.id !== id));
  };
  return (
    <div>
      <div className="faq-answer-content">
        <form style={{ width: "98%, margin: auto" }}>
          <div className="form-group" onClick={(e) => e.stopPropagation()}>
            <label htmlFor="Heading">Heading</label>
            <div
              className="styled-input-field"
              style={{
                width: "1101px",
                height: "50px",
              }}
            >
              <input
                type="text"
                placeholder="Enter Heading"
                className="input-text"
              />
              <div className="word-count-input">0/60</div>
            </div>
          </div>
          <div className="form-group" onClick={(e) => e.stopPropagation()}>
            <label htmlFor="Heading">Subheading</label>
            <div
              className="styled-input-field"
              style={{
                width: "1101px",
                height: "50px",
              }}
            >
              <input
                type="text"
                placeholder="Enter Subheading"
                className="input-text"
              />
              <div className="word-count-input">0/60</div>
            </div>
          </div>

          {/* <div
              className="d-flex justify-content-between align-items-center col-sm-12 mt-5"
              style={{ width: "1100px", height: "30px" }}
            >
              <div>
                <span>Services(1/3)</span>
              </div>
              <div className="d-flex  mr-2">
                <img src={deleteIcon} alt="delete Icon" />
                <span className="ml-3">+</span>
              </div>
            </div> */}

          {sections.map((section, index) => (
            <div key={section.id}>
              <div
                className="form-group row"
                onClick={(e) => e.stopPropagation()}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "1px solid #e7e8e9",
                  width: "1064px",
                  marginLeft: "4px",
                  borderRadius: "10px",
                  padding: "10px",
                }}
              >
                <div>
                  <p className="font-weight-bold h5">
                    Benefit {section.benefitNumber}
                  </p>
                  <p className="font-weight-bold h5">Heading Benefit</p>
                </div>
                <div>
                  <img
                    src={deleteIcon}
                    alt="Delete Icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteSection(section.id);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            </div>
          ))}

          <div
            className="d-flex justify-content-between align-items-center col-sm-12 mt-2"
            style={{ width: "1050px", height: "30px" }}
          >
            <div
              className="d-flex justify-content-between align-items-center col-sm-12 "
              style={{ width: "1100px", height: "30px" }}
            >
              <span className="font-weight-bold">
                Services({sections.length}/6)
              </span>
            </div>
            <div className="d-flex mr-2">
              <img
                src={deleteIcon}
                alt="delete Icon"
                onClick={(e) => e.stopPropagation()}
              />
              <img
                src={plus}
                alt="delete Icon"
                onClick={(e) => {
                  addSection();
                  e.stopPropagation();
                }}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>

          <div
            className="form-group row p-3"
            onClick={(e) => e.stopPropagation()}
          >
            <label className="col-sm-1 col-form-label">Text</label>
            <div
              className="styled-input-field col-sm-11"
              style={{
                width: "1101px",
                height: "50px",
              }}
            >
              <input
                type="text"
                placeholder="Enter Text"
                className="input-text"
              />
              <div className="word-count-input-half">0/60</div>
            </div>
          </div>

          <div
            className="form-group p-3 row"
            onClick={(e) => e.stopPropagation()}
          >
            <label className="col-sm-1 col-form-label">Service Image</label>
            <div
              className="styled-input-field col-sm-11"
              style={{
                width: "1101px",
                height: "50px",
              }}
            >
              <input
                type="file"
                placeholder="Choice Icon"
                className="input-text"
              />
            </div>
          </div>
          <div
            className="form-group p-3 row"
            onClick={(e) => e.stopPropagation()}
          >
            <label className="col-sm-1 col-form-label">Service Alt Text</label>
            <div
              className="styled-input-field col-sm-11"
              style={{
                width: "1101px",
                height: "50px",
              }}
            >
              <input
                type="text"
                placeholder="Enter Service Alt Text"
                className="input-text"
              />
              <div className="word-count-input-half">0/30</div>
            </div>
          </div>

          <div
            className="p-3"
            style={{
              width: "151px",
              height: "40px",
              marginLeft: "20px",
              borderRadius: "5px",
              background: "Linear-gradient(90deg, #9300B8 0%, #CB0064 100%)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              style={{
                color: "#FFF",
                fontSize: "17px",
                fontWeight: "700",
                lineHeight: "normal",
                border: "none",
                background: "transparent",
              }}
            >
              Save Section
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Interlinkings;
