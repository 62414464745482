import React from "react";
import arrowleft from "../../../images/arrow-left.png";
import rightArrow from "../../../images/rightArrow.png";
import "./EnterMetas.css";
import { useNavigate, useLocation } from "react-router-dom";
import { baseURL } from '../../../config/config';

const ThirdStep = ({ onNext }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const taskId = searchParams.get('taskId');
  const isUpdateForm = !! taskId;
  const handleNext = () => {
    isUpdateForm?navigate(`/ourServicesPage?taskId=${taskId}`):navigate(`/ourServicesPage`);
    onNext();
  };

  return (
    <>
      <div className="container">
        <div className="progress-indicator">
          <div className="d-flex justify-content-center align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
            >
              <circle cx="9.5" cy="9.5" r="9.5" fill="#00CE84" />
              <path
                d="M13.366 5.78223L7.88906 11.2652L5.63384 9.02942L4.13037 10.5199L7.88906 14.2995L14.8695 7.32596L13.366 5.78223Z"
                fill="white"
              />
            </svg>
            <div
              className="progress-bar filled-bar"
              style={{ marginLeft: "-5px" }}
            >
              <div className="progress-bar filled-bar"></div>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
            >
              <circle cx="9.5" cy="9.5" r="9.5" fill="#00CE84" />
              <path
                d="M13.366 5.78223L7.88906 11.2652L5.63384 9.02942L4.13037 10.5199L7.88906 14.2995L14.8695 7.32596L13.366 5.78223Z"
                fill="white"
              />
            </svg>
            <div
              className="progress-bar filled-bar"
              style={{ marginLeft: "-5px" }}
            >
              <div className="progress-bar filled-bar"></div>
            </div>
          </div>
          <div className="progress-bar semi-filled-bar"></div>
        </div>

        <div className="body-data">
          <div className="d-flex flex-column body-data-child">
            <div className="back-btn-parent" onClick={() => window.history.back()}>
              <img src={arrowleft} alt="" className="back-img" />
              <span className="back-text">Back</span>
            </div>
            <div className="steps-text mb-4" style={{ width: "510px" }}>
              <span>Step 3: Review Beardcrumb Schema</span>
            </div>

            <div className="textarea-container">
              <textarea
                className="textarea-style"
                placeholder={`<script type="application/ld+json">
  {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "home",
        "item": "https://www.myperfectwords.com/"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "essay writer",
        "item": "https://www.myperfectwords.com/essay-writer"
      },
      {
        "@type": "ListItem",
        "position": 3,
        "name": "Hire the Best Business Essay Writer Online",
        "item": "https://www.myperfectwords.com/essay-writer/business-essay-writer"
      }
    ]
  }
</script>`}
                rows={10}
                readOnly
              />
            </div>

            <div className="btnParents">
              <div className="button-container" style={{ width: "190px" }}>
                <button className="custom-button">Save & Continue Later</button>
              </div>
              <div className="styled-button" onClick={handleNext}>
                <div className="gradient-background">
                  <div className="button-content">
                    <span className="button-text text-white">Next</span>
                    <img src={rightArrow} alt="" srcSet="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThirdStep;
