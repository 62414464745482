import React, { useState ,useEffect} from "react";
import axios from 'axios';
import { useNavigate, useLocation } from "react-router-dom";
import "./OurServicesPage.css";
import { baseURL } from '../../../config/config';

const FeatureForm = ({ setCompleted, taskId }) => {
  const [heading, setHeading] = useState('');
  const [description, setDescription] = useState('');
  const [usp1, setUsp1] = useState('');
  const [usp2, setUsp2] = useState('');
  const [usp3, setUsp3] = useState('');
  const [usp4, setUsp4] = useState('');
  const [price, setPrice] = useState('');
  const [iconAltText, setIconAltText] = useState('');
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [isUpdateForm, setIsUpdateForm] = useState(false);

  useEffect(() => {
    if (taskId) {
      console.log("ParamId", taskId);
      axios.get(`${baseURL}/getFeatured-by-service/${taskId}`)
        .then(response => {
          console.log("response.data", response.data);
          const data = response.data;

          // Check if the response object has any keys (indicating it has content)
          setCompleted(Object.keys(data).length > 0);
          setIsUpdateForm(Object.keys(data).length > 0);

        })
        .catch(error => {
          console.error('Error fetching record:', error);
        });
    }
  }, [taskId]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const resetFields = () => {
    setHeading('');
    setDescription('');
    setUsp1('');
    setUsp2('');
    setUsp3('');
    setUsp4('');
    setPrice('');
    setIconAltText('');
    setFile(null);
  };

  const handleSaveFeatured = async (event) => {
    event.preventDefault();
    if ((!heading || !description || !usp1 || !usp2 || !usp3 || !usp4 || !price || !iconAltText || !file)) {
      setErrorMessage('All fields are required.');
      return;
    }

    setErrorMessage('');

    const serviceUrl = sessionStorage.getItem('servicePageUrl');
    const serviceId = sessionStorage.getItem('serviceId');
    const service_snippet_id = sessionStorage.getItem('service_snippet_id');
    const formData = new FormData();
    formData.append('service_snippet_id', service_snippet_id);
    formData.append('service_id', serviceId);
    formData.append('slug', serviceUrl);
    formData.append("heading", heading);
    formData.append("description", description);
    formData.append("usp1", usp1);
    formData.append("usp2", usp2);
    formData.append("usp3", usp3);
    formData.append("usp4", usp4);
    formData.append("price", price);
    formData.append("icon_alt_text", iconAltText);

    if (file) {
      formData.append("icon", file);
    }

    try {
      let response;
      if(isUpdateForm === false){
     response = await axios.post(`${baseURL}/saveFeatured`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    }
    else{
      response = await axios.put(`${baseURL}/updateFeatured/${taskId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    }
      console.log('Featured section saved:', response.data);
      resetFields();
      alert("Section saved successfully")
      //navigate(response.data.url); 
    } catch (error) {
      console.error('Error saving featured section:', error);
      alert(`Error: ${error.response ? error.response.data.error : error.message}`);
    }
  };

  return (
    <div className="faq-answer-content" onClick={(e) => e.stopPropagation()}>
      <form onSubmit={handleSaveFeatured} style={{ width: "98%", margin: "auto" }}>
        <div className="p-3">
          <h3 className="font-weight-bold">Featured</h3>
        </div>
        {[
          { label: "Heading", value: heading, setValue: setHeading, maxLength: 20 },
          { label: "Description", value: description, setValue: setDescription, maxLength: 30 },
          { label: "USP 1", value: usp1, setValue: setUsp1, maxLength: 30 },
          { label: "USP 2", value: usp2, setValue: setUsp2, maxLength: 30 },
          { label: "USP 3", value: usp3, setValue: setUsp3, maxLength: 30 },
          { label: "USP 4", value: usp4, setValue: setUsp4, maxLength: 30 },
          { label: "Icon Alt Text", value: iconAltText, setValue: setIconAltText },
        ].map((field, index) => (
          <div key={index} className="form-group p-3 row">
            <label className="col-sm-1 col-form-label">{field.label}</label>
            <div className="styled-input-field col-sm-11">
              <input
                type="text"
                name={field.label.toLowerCase().replace(' ', '_')}
                placeholder={`Enter ${field.label}`}
                className="input-text"
                value={field.value}
                onChange={(e) => field.setValue(e.target.value)}
              />
              {field.maxLength && (
                <div className="word-count-input-half">
                  {field.value.length}/{field.maxLength}
                </div>
              )}
            </div>
          </div>
        ))}
        <div className="form-group p-3 row">
          <label className="col-sm-1 col-form-label">Price</label>
          <div className="col-sm-11 abc d-flex align-items-center">
            <div className="input-group mb-2 p-0 m-0" style={{ width: "100%" }}>
              <div className="input-group-prepend">
                <div className="input-group-text">$</div>
              </div>
              <input
                type="text"
                name="price"
                className="form-control"
                placeholder="Enter Price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="form-group p-3 row">
          <label className="col-sm-1 col-form-label">Icon</label>
          <div className="col-sm-11">
            <input
              type="file"
              name="icon"
              className="form-control-file"
              onChange={handleFileChange}
            />
          </div>
        </div>
        <div className="p-3">
         

<div className="form-group p-3 row">
           <label className="col-sm-1 col-form-label">Icon Alt Text</label>
           <div className="styled-input-field col-sm-11">
            <input
              type="text"
              name="icon_alt_text"
              placeholder="Enter Icon Alt Text"
              className="input-text"
              value={iconAltText}
              onChange={(e) => setIconAltText(e.target.value)}
            />
          </div>
        </div>
        <div>
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        <div
              className="p-3"
              style={{
                width: "151px",
                height: "40px",
                marginLeft: "20px",
                borderRadius: "5px",
                background: "linear-gradient(90deg, #9300B8 0%, #CB0064 100%)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                type="submit"
                style={{
                  color: "#FFF",
                  fontSize: "17px",
                  fontWeight: "700",
                  lineHeight: "normal",
                  border: "none",
                  background: "transparent",
                }}
              >
                Save Section
              </button>
            </div>
            </div>
        </div>
      </form>
    </div>
  );
};

export default FeatureForm;
