import React, { useState, useEffect } from "react";
import "./OurServicesPage.css";
import { baseURL } from '../../../config/config';
import axios from 'axios';

const Cta = ({ setCompleted, taskId }) => {

  const [heading, setHeading] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [buttonAltText, setButtonAltText] = useState("");
  const [errorMessage, setErrorMessage] = useState('');
  const [isUpdateForm, setIsUpdateForm] = useState(false);

  useEffect(() => {
    if (taskId) {
      console.log("ParamId", taskId);
      axios.get(`${baseURL}/ctaSections/${taskId}`)
        .then(response => {
          console.log("response.data", response.data);
          const data = response.data;

          // Check if the response object has any keys (indicating it has content)
          setCompleted(Object.keys(data).length > 0);
          setIsUpdateForm(Object.keys(data).length > 0);

        })
        .catch(error => {
          console.error('Error fetching record:', error);
        });
    }
  }, [taskId]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!heading || !buttonText || !buttonAltText) {
      setErrorMessage('All fields are required.');
      return;
    }
    // If validation passes, clear the error message and proceed with form submission
    setErrorMessage('');

    const serviceUrl = sessionStorage.getItem('servicePageUrl');
    const serviceId = sessionStorage.getItem('serviceId');

    const newCta = { heading, button_text: buttonText, button_alt_text: buttonAltText, slug: serviceUrl, service_id: serviceId };
    try{
    let response;
    if (isUpdateForm === false) {
      response = await fetch(`${baseURL}/ctaSections`, {
        method: 'POST', // Change to PUT if backend expects PUT
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newCta),
      });
    }
    else {
      response = await fetch(`${baseURL}/ctaSections/${taskId}`, {
        method: 'PUT', // Change to PUT if backend expects PUT
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newCta),
      });
    }
    if (response.ok) {
      alert("Section saved successfully");
      setHeading("");
      setButtonText("");
      setButtonAltText("");

    }
  }
    catch (error) {
    console.error('Error saving section:', error);
  };
}

return (
  <div>
    <div className="faq-answer-content">
      <form style={{ width: "98%, margin: auto" }} onSubmit={handleSubmit} onClick={(e) => e.stopPropagation()}>
        <div className="form-group" onClick={(e) => e.stopPropagation()}>
          <label htmlFor="Heading">Heading</label>
          <div
            className="styled-input-field"
            style={{
              width: "1101px",
              height: "50px",
            }}
          >
            <input
              type="text"
              placeholder="Enter Heading"
              className="input-text"
              value={heading}
              onChange={(e) => setHeading(e.target.value)}
              maxLength={150}
            />
            <div className="word-count-input">0/150</div>
          </div>
        </div>
        <div className="form-group" onClick={(e) => e.stopPropagation()}>
          <label htmlFor="Heading">Button Text</label>
          <div
            className="styled-input-field"
            style={{
              width: "1101px",
              height: "50px",
            }}
          >
            <input
              type="text"
              placeholder="Enter Button Text"
              className="input-text"
              value={buttonText}
              onChange={(e) => setButtonText(e.target.value)}
              maxLength={20}
            />
            <div className="word-count-input">0/20</div>
          </div>
        </div>

        <div className="form-group" onClick={(e) => e.stopPropagation()}>
          <label htmlFor="Heading">Button Alt Text</label>
          <div
            className="styled-input-field"
            style={{
              width: "1101px",
              height: "50px",
            }}
          >
            <input
              type="text"
              placeholder="Enter Alt Button Text"
              className="input-text"
              value={buttonAltText}
              onChange={(e) => setButtonAltText(e.target.value)}

            />
          </div>
        </div>
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        <div
          className="p-3"
          style={{
            width: "151px",
            height: "40px",
            marginLeft: "20px",
            borderRadius: "5px",
            background: "Linear-gradient(90deg, #9300B8 0%, #CB0064 100%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            type="submit"
            style={{
              color: "#FFF",
              fontSize: "17px",
              fontWeight: "700",
              lineHeight: "normal",
              border: "none",
              background: "transparent",
            }}
          >
            Save Section
          </button>
        </div>
      </form>
    </div>
  </div>
)
}

export default Cta
