import React, { useEffect, useState } from "react";
//import deleteIcon from "../../../images/deleteIcon.png";
import rightArrow from "../../../images/rightArrow.png";
import plus from "../../../images/plus2.svg";
import minus from "../../../images/minus.svg";
import "./OurServicesPage.css";
import FeatureForm from "./FeatureForm";
import NonFeaturesForm from "./NonFeaturesForm";
import SectionOneBanner from "./SectionOneBanner";
import SectionTwo from "./SectionTwo";
import SectionThree from "./SectionThree";
import SectionFourth from "./SectionFourth";
import SectionFifth from "./SectionFifth";
import Reviews from "./Reviews";
import Faqs from "./Faqs";
import SeoContent from "./SeoContent";
import Cta from "./Cta";
import Interlinkings from "./Interlinkings";
import axios from 'axios';
import { baseURL } from '../../../config/config';
import { useNavigate, useLocation } from "react-router-dom";

function OurServicesPage() {
  const [open, setOpen] = useState({});
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const taskId = searchParams.get('taskId');
  const isUpdateForm = !!taskId;

  const toggleCollapse = (index) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [index]: !prevOpen[index],
    }));
  };

  const [selectedRadio, setSelectedRadio] = useState(null);
  const [selectedService, setSelectedService] = useState(null);

  const [services, setServices] = useState([]);

  const [selectedOption, setSelectedOption] = useState("");
  const [completed, setCompleted] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [isFeaturedDisabled, setIsFeaturedDisabled] = useState(false);


  useEffect(() => {
    const fetchFeatured = async () => {
      try {
        const response = await axios.get(`${baseURL}/checkFeatured/${selectedService}`);

        // If the request is successful (status code 200), handle the response here
        setErrorMessage(''); // Clear any previous error messages

        setIsFeaturedDisabled(true); // Disable the Featured button if it exists

      } catch (error) {
        if (error.response && error.response.status === 404) {
          // If a 404 error occurs, set the appropriate error message
          setErrorMessage('No featured section found for the selected service type.');
        } else {
          // Handle any other errors
          console.error('Error fetching services:', error);
          setErrorMessage('An error occurred while fetching the service.');
        }
        setIsFeaturedDisabled(false);
      }
    };

    fetchFeatured();
  }, [selectedService]);



  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get(`${baseURL}/select`); // Ensure this URL is correct
        setServices(response.data);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    fetchServices();
  }, []);



  const handleServiceChange = (event) => {
    setSelectedService(event.target.value);
    sessionStorage.setItem('service_snippet_id', event.target.value);

  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleRadioChange = (e) => {
    setSelectedRadio(e.target.value);
  };

  const handleInputChange = (id, event) => {
    const newSections = sections.map((section) => {
      if (section.id === id) {
        return { ...section, name: event.target.value };
      }
      return section;
    });
    setSections(newSections);
  };

  const [sections, setSections] = useState([]);

  const addSection = () => {
    setSections([
      ...sections,
      { id: Date.now(), benefitNumber: sections.length + 1 },
    ]);
  };

  const deleteSection = (id) => {
    setSections(sections.filter((section) => section.id !== id));
  };



  const faqs = [
    {
      question: "Section 0: Service Snippet",
      answer: (
        <div className="faq-answer-content">
          <div class="input-group mb-3">
            <div class="input-group">
              <label for="inputGroupSelect01">Select Main Service</label>
            </div>
            {errorMessage && <p className="text-warning" style={{ fontStyle: 'bold', fontSize: '1.2rem' }}>{errorMessage}</p>}
          </div>

          <select
            className="form-control form-control-lg"
            id="inputGroupSelect01"
            onChange={handleServiceChange}
            onClick={(e) => e.stopPropagation()}
          >
            <option value="">Select Service Type</option>
            {services.map((service) => (
              <option key={service.id} value={service.id}>
                {service.name}
              </option>
            ))}
          </select>


          {selectedService && (
            <div
              className="radio-buttons-container"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  value="Featured"
                  disabled={isFeaturedDisabled}
                  onChange={handleRadioChange}
                />
                <label
                  className="form-check-label font-weight-bold"
                  htmlFor="flexRadioDefault1"
                >
                  Featured
                </label>
              </div>
              <div className="gap"></div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  value="Non-featured"
                  onChange={handleRadioChange}
                />
                <label
                  className="form-check-label font-weight-bold"
                  htmlFor="flexRadioDefault2"
                >
                  Non-featured
                </label>
              </div>
            </div>
          )}

          {selectedRadio === "Featured" && (
            <div className="featured-form">

              <FeatureForm taskId={taskId} />

            </div>
          )}

          {selectedRadio === "Non-featured" && (
            <div className="non-featured-form">
              {/* Your form fields for Non-featured here */}
              <NonFeaturesForm taskId={taskId} />
              {/* Add more form fields as needed */}
            </div>
          )}
        </div>
      ),
    },
    {
      question: "Section 1: Banner",
      answer: <SectionOneBanner taskId={taskId} />,
    },
    {
      question: "Section 2: Features",
      answer: <SectionTwo taskId={taskId} />,
    },
    {
      question: "Section 3: How to Use/Order Section",
      answer: <SectionThree taskId={taskId} />,
    },
    {
      question: "Section 4: Samples",
      answer: <SectionFourth taskId={taskId} />,
    },
    {
      question: "Section 5: Pricing",
      answer: <SectionFifth taskId={taskId} />,
    },
    {
      question: "Section 6: Reviews",
      answer: <Reviews taskId={taskId} />,
    },
    {
      question: "Section 7: FAQs",
      answer: <Faqs taskId={taskId} />,
    },
    {
      question: "Section 8: SEO Content",
      answer: <SeoContent taskId={taskId} />,
    },
    {
      question: "Section 9: CTA 1",
      answer: <Cta taskId={taskId} />,
    },
    {
      question: "Section 10: Interlinkings",
      answer: <Interlinkings />,
    },
  ];

  const [completedSections, setCompletedSections] = useState(Array(faqs.length).fill(false));

  const setCompletedForSection = (index, status) => {
    console.log(`Updating index ${index} to ${status}`);

    setCompletedSections(prevSections => {
      const updatedSections = [...prevSections];
      updatedSections[index] = status;
      console.log(`Updated array`, updatedSections);
      return updatedSections;
    });
  };

  useEffect(() => {
    // Any side effects or actions needed when completedSections changes
  }, [completedSections]);

  return (
    <div className="container faq-container py-3 px-0">
      <div className="row">
        {faqs.map((faq, index) => (
          <div className="col-12 mb-4" key={index}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div className="col-12">
                <div
                  className="faq-item"
                  onClick={() => toggleCollapse(index)}
                  style={{ cursor: "pointer" }}
                >
                  <div className="faq-question d-flex justify-content-between align-items-center">
                    <span
                      className={`question-section ${open[index] ? "expanded" : ""}`}
                      style={{ color: open[index] ? "#CB0064" : "black" }}
                    >
                      {faq.question}
                    </span>
                    <span className="plus2">{open[index] ? <img src={minus} style={{ width: '1.0625rem', height: '1.0625rem' }} /> : <img src={plus} style={{ width: '1.0625rem', height: '1.0625rem' }} />}</span>
                  </div>
                  <div className={`faq-answer ${open[index] ? "show" : ""}`}>
                    {React.cloneElement(faq.answer, { setCompleted: (status) => setCompletedForSection(index, status) })}
                  </div>
                </div>
              </div>
              {completedSections[index] && (
                <div
                  className="position-absolute"
                  style={{
                    right: '-60px', // Adjust this value based on how far outside you want the icon
                    top: '50%',
                    transform: 'translateY(-50%)'
                  }}
                >
                  <img width="34" height="34" src="https://img.icons8.com/ios/50/checked--v1.png" alt="checked--v1" />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="button-container">
        <div className="button-wrapper">
          <div className="rectangle">
            <div className="button-text">Change Sections Order</div>
          </div>
        </div>
        <div className="styled-button">
          <div className="gradient-background">
            <div className="button-content">
              <span className="button-text text-white">Next</span>
              <img src={rightArrow} alt="" srcSet="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurServicesPage;
