import React, { useState, useEffect, useRef } from "react";
import deleteIcon from "../../../images/trash-2.svg";
import plus from "../../../images/plus3.svg";
import axios from 'axios';
import { baseURL } from '../../../config/config';

const SectionFourth = ({setCompleted, taskId}) => {
  const [sections, setSections] = useState([]);
  const [categoryLogos, setCategoryLogos] = useState([]);
  const [heading, setHeading] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [iconFile, setIconFile] = useState(null);
  const [iconAltText, setIconAltText] = useState('');
  const fileInputRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isUpdateForm, setIsUpdateForm] = useState(false);


  useEffect(() => {
    if (taskId) {
      console.log("ParamId", taskId);
      axios.get(`${baseURL}/sectionFour/${taskId}`)
        .then(response => {
          console.log("response.data", response.data);
          const data = response.data;
  
          const hasData = data && data.steps && data.steps.length > 0;

          setCompleted(hasData);
          setIsUpdateForm(hasData);
  
        })
        .catch(error => {
          console.error('Error fetching record:', error);
        });
    }
  }, [taskId]);  


  const deleteSection = (id) => {
    setSections(sections.filter((section) => section.id !== id));
  };

  const deleteLogo = (id) => {
    setCategoryLogos(categoryLogos.filter((section) => section.id !== id));
  };


  useEffect(() => {
    console.log('sections', sections)
    console.log(`legthd`, sections.length);


  }, [sections]);

  const handleFileChange = (e) => {
    setIconFile(e.target.files[0]);
  };

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setIconFile(null);
  };

  const areArraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
    return true;
  };

  const addSection = () => {
    if ((heading === '' || categoryName === '' || categoryLogos.length === 0)) {
      setErrorMessage('All fields are required.');
     
    }
    const newSection = {
      id: Date.now(),
      categoryName,
      categoryLogos,
    };

    setSections((prevSections) => {
      // Check if the new section already exists to prevent duplicates
      const sectionExists = prevSections.some((section) =>
        section.categoryName === newSection.categoryName &&
        areArraysEqual(section.categoryLogos, newSection.categoryLogos)
      );
      if (sectionExists) return prevSections;

      return [...prevSections, newSection];
    });

    // Clear form fields
    setCategoryName('');
    setCategoryLogos([]);
    resetFileInput();
    setIconAltText('');
  };


  const addLogo = () => {
    if ((iconAltText === '' || !iconFile)) {
      setErrorMessage('All fields are required.');
     
    }
    const newSection = {
      id: Date.now(),
      iconFile,
      iconAltText,
    };

    setCategoryLogos((prevSections) => {
      // Check if the new section already exists to prevent duplicates
      const sectionExists = prevSections.some((section) =>
        section.iconFile === newSection.iconFile &&
        section.iconAltText === newSection.iconAltText
      );
      if (sectionExists) return prevSections;

      return [...prevSections, newSection];
    });

    // Clear form fields
    setIconAltText('');
    resetFileInput();

  };



  const handleSaveSection = async (event) => {
    event.preventDefault();
    if (sections.length < 5) {
      setErrorMessage('There must be at least 5 categories.');
      return;
    }

    // If validation passes, clear the error message and proceed with form submission
    setErrorMessage('');


    // Add the new section if necessary
    if (heading !== '' && categoryName !== '' && categoryLogos.length > 0) {
      addSection(); // Ensure the section is added
    }

    // Delay to ensure state update is reflected
    await new Promise((resolve) => setTimeout(resolve, 100));

    // Use callback form of setSections to get updated sections
    const serviceUrl = sessionStorage.getItem('servicePageUrl');
    const serviceId = sessionStorage.getItem('serviceId');
    const formData = new FormData();
    formData.append('heading', heading);
    formData.append('slug', serviceUrl);
    formData.append('service_id', serviceId);

    // Add sections data to formData
    sections.forEach((section, index) => {
      formData.append(`categoriesData[${index}][category_name]`, section.categoryName);

      section.categoryLogos.forEach((item, index1) => {
        formData.append(`categoriesData[${index}][icon_alt_text][${index1}]`, item.iconAltText);
        if (item.iconFile) {
          formData.append(`icon_path`, item.iconFile);
        }
      });
    });



    // Log FormData contents
    for (const [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }
    console.log(sections.length, categoryLogos.length);

    if (sections.length === 5) {
      
      try {
        let response;
        if(isUpdateForm === false){
        response = await fetch(`${baseURL}/sectionFour`, {
          method: 'POST', // Change to PUT if backend expects PUT
          body: formData,
        });
      }
      else
      {
        response = await fetch(`${baseURL}/sectionFour/${taskId}`, {
          method: 'PUT', // Change to PUT if backend expects PUT
          body: formData,
        });
      }
    
        if (response.ok) {
          alert('Section saved successfully');
          console.log('Section saved successfully');
    
          // Reset form and states
          setHeading('');
          setCategoryName('');
          setCategoryLogos([]);
          setIconFile(null);
          setIconAltText('');
          setSections([]);
        } else {
          console.error('Error saving section:', response.statusText);
        }
      } catch (error) {
        console.error('Error saving section:', error);
      } finally {
        // Optionally handle any final state cleanup
        // setIsProcessing(false);
        // setIsSaving(false);
      }
    }
  };



  return (
    <div>
      <div className="faq-answer-content">
        <form onSubmit={handleSaveSection} style={{ width: "98%, margin: auto" }} onClick={(e) => e.stopPropagation()}>
          <div className="form-group" onClick={(e) => e.stopPropagation()}>
            <label htmlFor="Heading">Heading</label>
            <div
              className="styled-input-field"
              style={{
                width: "1101px",
                height: "50px",
              }}
            >
              <input
                type="text"
                placeholder="Enter Heading"
                name="heading"
                className="input-text"
                value={heading}
                onChange={(e) => setHeading(e.target.value)}
                maxLength={60}
              />
              <div className="word-count-input">0/60</div>
            </div>
          </div>

          {sections.map((section, index) => (
            <div key={section.id}>
              <div
                className="form-group row"
                onClick={(e) => e.stopPropagation()}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "1px solid #e7e8e9",
                  width: "1064px",
                  marginLeft: "4px",
                  borderRadius: "10px",
                  padding: "10px",
                }}
              >
                <div>
                  <p className="font-weight-bold small" style={{ color: 'rgba(203, 0, 100, 1)' }}>
                    Category {section.benefitNumber}
                  </p>
                  <p className="font-weight-bold p">{section.categoryName}</p>
                </div>
                <div>
                  <img
                    src={deleteIcon}
                    alt="Delete Icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteSection(section.id);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            </div>
          ))}

          {sections.length <= 4 && (
            <>
              <div
                className="d-flex justify-content-between align-items-center col-sm-12 mt-2"
                style={{ width: "1050px", height: "30px" }}
              >
                <div
                  className="d-flex justify-content-between align-items-center col-sm-12 "
                  style={{ width: "1100px", height: "30px" }}
                >
                  <span className="font-weight-bold">
                    Categories({sections.length + 1}/5)
                  </span>
                </div>
                <div className="d-flex mr-2">
                  <img
                    src={deleteIcon}
                    alt="delete Icon"
                    onClick={(e) => e.stopPropagation()}
                  />
                  <img
                    src={plus}
                    alt="delete Icon"
                    onClick={(e) => {
                      addSection();
                      e.stopPropagation();
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>

              <div
                className="form-group row p-3"
                onClick={(e) => e.stopPropagation()}
              >
                <label className="col-sm-1 col-form-label">Name</label>
                <div
                  className="styled-input-field col-sm-11"
                  style={{
                    width: "1101px",
                    height: "50px",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Enter Category Name"
                    className="input-text"
                    maxLength={30}
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                  />
                  <div className="word-count-input-half">0/30</div>
                </div>
              </div>

              {categoryLogos.map((section, index) => (
                <div key={section.id}>
                  <div
                    className="form-group row"
                    onClick={(e) => e.stopPropagation()}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      border: "1px solid #e7e8e9",
                      width: "1064px",
                      marginLeft: "4px",
                      borderRadius: "10px",
                      padding: "10px",
                    }}
                  >
                    <div>
                      <p className="font-weight-bold small" style={{ color: 'rgba(203, 0, 100, 1)' }}>
                        Category 
                      </p>
                      <p className="font-weight-bold p">{`Logo ${index + 1}`}</p>
                    </div>
                    <div>
                      <img
                        src={deleteIcon}
                        alt="Delete Icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteLogo(section.id);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                </div>
              ))}


              {categoryLogos.length <= 5 && (
                <>
                  <div
                    className="d-flex justify-content-between align-items-center col-sm-12 mt-2"
                    style={{ width: "1100px", height: "30px" }}
                  >
                    <div>
                      <span>Logo Samples</span>
                    </div>
                    <div className="d-flex  mr-2">
                      <img src={deleteIcon} alt="delete Icon" />
                      <img
                        src={plus}
                        alt="add Icon"
                        onClick={(e) => {
                          addLogo();
                          e.stopPropagation();
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                  <div
                    className="form-group p-3 row"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <label className="col-sm-1 col-form-label">Logo Image</label>
                    <div
                      className="styled-input-field col-sm-11"
                      style={{
                        width: "1101px",
                        height: "50px",
                      }}
                    >
                      <input
                        type="file"
                        name="file"
                        className="input-text"
                        onChange={handleFileChange}
                        ref={fileInputRef}
                      />
                    </div>
                  </div>
                  <div
                    className="form-group p-3 row"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <label className="col-sm-1 col-form-label">
                      Logo Image Alt Text
                    </label>
                    <div
                      className="styled-input-field col-sm-11"
                      style={{
                        width: "1101px",
                        height: "50px",
                      }}
                    >
                      <input
                        type="text"
                        name="iconAltText"
                        placeholder="Enter Logo Image Alt Text"
                        className="input-text"
                        value={iconAltText}
                        onChange={(e) => setIconAltText(e.target.value)}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          <div
            className="p-3"
            style={{
              width: "151px",
              height: "40px",
              marginLeft: "20px",
              borderRadius: "5px",
              background: "Linear-gradient(90deg, #9300B8 0%, #CB0064 100%)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              type="submit"
              style={{
                color: "#FFF",
                fontSize: "17px",
                fontWeight: "700",
                lineHeight: "normal",
                border: "none",
                background: "transparent",
              }}
            >
              Save Section
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default SectionFourth
