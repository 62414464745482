import React from "react";
import { useNavigate } from "react-router-dom";
import bottomArrow from "../../images/Group 40.png";
import "./Header.css";

const Header = ({ onAddNewPage }) => {
  const navigate = useNavigate();

  const toggleEnterMetas = () => {
    navigate("/enterMetas");
    onAddNewPage(); 
  };

  return (
    <div className="filter-bar">
      <header
        className="container-fluid container d-flex justify-content-between align-items-center"
        style={{ height: "73px" }}
      >
        <div className="left-side-header">
          <div className="left-menu">
            <div className="menu-bar"></div>
            <div className="menu-bar"></div>
            <div className="menu-bar"></div>
          </div>

          <div className="status-button">
            <div className="rectangle">
              <div className="status">Status</div>
              <img src={bottomArrow} alt="Bottom Arrow" />
            </div>
          </div>
        </div>

        <div className="right-button">
          <div className="rectangle">
            <span className="add-new-page" onClick={toggleEnterMetas}>
              Add New Page
            </span>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
