import React, { useState, useEffect } from "react";
import deleteIcon from "../../../images/trash-2.svg";
import plus from "../../../images/plus3.svg";
import axios from "axios";
import { baseURL } from '../../../config/config';

const Faqs = ({setCompleted, taskId}) => {
  const [sections, setSections] = useState([]);
  const [heading, setHeading] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [errorMessage, setErrorMessage] = useState('');
  const [isUpdateForm, setIsUpdateForm] = useState(false);


  useEffect(() => {
    if (taskId) {
      console.log("ParamId", taskId);
      axios.get(`${baseURL}/faqs/${taskId}`)
        .then(response => {
          console.log("response.data", response.data);
          const data = response.data;
  
          // Check if the response object has any keys (indicating it has content)
          setCompleted(Object.keys(data).length > 0);
          setIsUpdateForm(Object.keys(data).length > 0);
  
        })
        .catch(error => {
          console.error('Error fetching record:', error);
        });
    }
  }, [taskId]);  


  useEffect(() => {

    console.log('sections', sections)
    console.log(`legthd`, sections.length);


  }, [sections]);


  const addSection = () => {
    if (!heading || !answer || !question) {
      setErrorMessage('Please fill in all fields.');
      return;
    }
    const newSection = {
      id: Date.now(),
      question,
      answer,
    };

    setSections((prevSections) => {
      // Check if the new section already exists to prevent duplicates
      const sectionExists = prevSections.some((section) =>
        section.question === newSection.question &&
        section.answer === newSection.answer
      );
      if (sectionExists) return prevSections;

      return [...prevSections, newSection];
    });

    // Clear form fields

    setQuestion('');
    setAnswer('');

  };


  const deleteSection = (id) => {
    setSections(sections.filter((section) => section.id !== id));
  };

  const handleSaveSection = async (event) => {
    event.preventDefault();

    // if (sections.length < 7) {
    //   setErrorMessage('Must contain at least 7 items.');
    //   return;
    // }

    // If validation passes, clear the error message and proceed with form submission
    setErrorMessage('');

    await new Promise((resolve) => setTimeout(resolve, 100));
    const serviceUrl = sessionStorage.getItem('servicePageUrl');
    const serviceId = sessionStorage.getItem('serviceId');
    const data = {
      heading: heading,
      slug: serviceUrl,
      service_id: serviceId,
      singleFaqs: sections.map(section => ({
        question: section.question,
        answer: section.answer,
        
      })),
    };

    const jsonData = JSON.stringify(data);

    // Log FormData contents
    Object.entries(data).forEach(([key, value]) => {
      console.log(`${key}: ${JSON.stringify(value)}`);
    });

    try {
      let response;
      if(isUpdateForm === false){
      response = await fetch(`${baseURL}/faqs`, {
        method: 'POST', // Change to PUT if backend expects PUT
        headers: {
          'Content-Type': 'application/json',
        },
        body: jsonData,
      });
    }else{
      response = await fetch(`${baseURL}/faqs/${taskId}`, {
        method: 'PUT', // Change to PUT if backend expects PUT
        headers: {
          'Content-Type': 'application/json',
        },
        body: jsonData,
      });
    }

      if (response.ok) {
        alert('Section saved successfully');
        console.log('Section saved successfully');
        // Reset form and states

        setHeading('');
        setQuestion('');
        setAnswer('');
        setSections([]);

      } else {
        console.error('Error saving section:', response.statusText);
      }
    } catch (error) {
      console.error('Error saving section:', error);
    } finally {
      // setIsProcessing(false);
      // setIsSaving(false);
    }
  };


  return (
    <div>
      <div className="faq-answer-content">
        <form onSubmit={handleSaveSection} style={{ width: "98%, margin: auto" }} onClick={(e) => e.stopPropagation()}>
          <div className="form-group" onClick={(e) => e.stopPropagation()}>
            <label htmlFor="Heading">Heading</label>
            <div
              className="styled-input-field"
              style={{
                width: "1101px",
                height: "50px",
              }}
            >
              <input
                type="text"
                placeholder="Meta Title"
                className="input-text"
                name="heading"
                value={heading}
                onChange={(e) => setHeading(e.target.value)}
              />
              <div className="word-count-input">0/60</div>
            </div>
          </div>

          {sections.map((section, index) => (
            <div key={section.id}>
              <div
                className="form-group row"
                onClick={(e) => e.stopPropagation()}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "1px solid #e7e8e9",
                  width: "1064px",
                  marginLeft: "4px",
                  borderRadius: "10px",
                  padding: "10px",
                }}
              >
                <div>
                  <p className="font-weight-bold small" style={{ color: 'rgba(203, 0, 100, 1)' }}>
                    FAQ {index + 1}
                  </p>

                </div>
                <div>
                  <img
                    src={deleteIcon}
                    alt="Delete Icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteSection(section.id);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            </div>
          ))}

          {sections.length <= 6 && (
            <>
              <div
                className="d-flex justify-content-between align-items-center col-sm-12 mt-2"
                style={{ width: "1050px", height: "30px" }}
              >
                <div
                  className="d-flex justify-content-between align-items-center col-sm-12 "
                  style={{ width: "1100px", height: "30px" }}
                >
                  <span className="font-weight-bold">
                    Questions({sections.length + 1}/7)
                  </span>
                </div>
                <div className="d-flex mr-2">
                  <img
                    src={deleteIcon}
                    alt="delete Icon"
                    onClick={(e) => e.stopPropagation()}
                  />
                  <img
                    src={plus}
                    alt="delete Icon"
                    onClick={(e) => {
                      addSection();
                      e.stopPropagation();
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
              <div
                className="form-group row p-3"
                onClick={(e) => e.stopPropagation()}
              >
                <label className="col-sm-1 col-form-label">Question</label>
                <div
                  className="styled-input-field col-sm-11"
                  style={{
                    width: "1101px",
                    height: "50px",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Enter Question"
                    className="input-text"
                    name="question"
                    maxLength={360}
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                  />
                  <div className="word-count-input-half">0/360</div>
                </div>
              </div>

              <div className="form-group row  p-3" onClick={(e) => e.stopPropagation()}>
                <label htmlFor="exampleFormControlInput1" className="col-sm-1 col-form-label">Answer</label>

                <div
                  className="styled-input-field col-sm-11"
                  style={{
                    width: "1101px",
                    height: "150px",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Enter Answer"
                    className="input-text"
                    name="answer"
                    value={answer}
                    onChange={(e) => setAnswer(e.target.value)}
                  />
                  <div className="word-count-input-half"></div>
                
              </div>
              </div>
            </>
          )}
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          <div
            className="p-3"
            style={{
              width: "151px",
              height: "40px",
              marginLeft: "20px",
              borderRadius: "5px",
              background: "Linear-gradient(90deg, #9300B8 0%, #CB0064 100%)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              type="submit"
              style={{
                color: "#FFF",
                fontSize: "17px",
                fontWeight: "700",
                lineHeight: "normal",
                border: "none",
                background: "transparent",
              }}
            >
              Save Section
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Faqs;
