import React, { useState, useEffect, useRef } from 'react';
import deleteIcon from "../../../images/trash-2.svg";
import plus from "../../../images/plus3.svg";
import "./OurServicesPage.css";
import { baseURL } from '../../../config/config';
import axios from 'axios';

const SectionTwo = ({setCompleted, taskId}) => {
  const [sections, setSections] = useState([]);
  const [heading, setHeading] = useState('');
  const [benefitHeading, setBenefitHeading] = useState('');
  const [subheading, setSubheading] = useState('');
  const [benefitSubheading, setBenefitSubheading] = useState('');
  const [iconFile, setIconFile] = useState(null);
  const [iconAltText, setIconAltText] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const fileInputRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isUpdateForm, setIsUpdateForm] = useState(false);


  useEffect(() => {
    if (taskId) {
      console.log("ParamId", taskId);
      axios.get(`${baseURL}/sectionTwo/${taskId}`)
        .then(response => {
          console.log("response.data", response.data);
          const data = response.data;
  
          const hasData = data && data.benefits && data.benefits.length > 0;

          setCompleted(hasData);
          setIsUpdateForm(hasData);
  
        })
        .catch(error => {
          console.error('Error fetching record:', error);
        });
    }
  }, [taskId]);  


  useEffect(() => {
    console.log('sections', sections)
    console.log(`legthd`, sections.length);
  }, [sections]);

  const handleFileChange = (e) => {
    setIconFile(e.target.files[0]);
  };

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setIconFile(null);
  };


  const addSection = () => {
    if ((!heading || !benefitHeading || !subheading || !benefitSubheading || !iconFile || !iconAltText)) {
      setErrorMessage('All fields are required.');
      return;
    }

    const newSection = {
      id: Date.now(),
      benefitHeading,
      benefitSubheading,
      iconFile,
      iconAltText,
    };

    setSections((prevSections) => {
      // Check if the new section already exists to prevent duplicates
      const sectionExists = prevSections.some((section) =>
        section.benefitHeading === newSection.benefitHeading &&
        section.benefitSubheading === newSection.benefitSubheading &&
        section.iconAltText === newSection.iconAltText &&
        section.iconFile === newSection.iconFile
      );
      if (sectionExists) return prevSections;

      return [...prevSections, newSection];
    });

    // Clear form fields
    setBenefitHeading('');
    setBenefitSubheading('');
    resetFileInput();
    setIconAltText('');
  };

  const deleteSection = (id) => {
    setSections(sections.filter((section) => section.id !== id));
  };


  const handleSaveSection = async (event) => {
    event.preventDefault();

    if (sections.length < 6) {
      setErrorMessage('Benefits must contain at least 6 items.');
      return;
    }

    // If validation passes, clear the error message and proceed with form submission
    setErrorMessage('');
    // Prevent duplicate submissions
    if (isProcessing) return;
    setIsProcessing(true);

    // Add the new section if necessary
    if (benefitHeading !== '' || benefitSubheading !== '' || iconFile !== null || iconAltText !== '') {
      addSection(); // Ensure the section is added
    }

    // Delay to ensure state update is reflected
    await new Promise((resolve) => setTimeout(resolve, 100));

    // Use callback form of setSections to get updated sections
    const serviceUrl = sessionStorage.getItem('servicePageUrl');
    const serviceId = sessionStorage.getItem('serviceId');
    const formData = new FormData();
    formData.append('heading', heading);
    formData.append('subheading', subheading);
    formData.append('slug', serviceUrl);
    formData.append('service_id', serviceId);

    // Add sections data to formData
    if (sections.length === 0) {
      // If sections is empty, directly append form field values
      if (benefitHeading !== '' || benefitSubheading !== '' || iconFile !== null || iconAltText !== '') {
        formData.append('benefitsData[0][heading]', benefitHeading);
        formData.append('benefitsData[0][subheading]', benefitSubheading);
        formData.append('benefitsData[0][icon_alt_text]', iconAltText);
        if (iconFile) {
          formData.append('icon_path', iconFile); // Each file with unique key
        }
      }
    } else {
      // If sections is not empty, add sections data to formData
      sections.forEach((section, index) => {
        formData.append(`benefitsData[${index}][heading]`, section.benefitHeading);
        formData.append(`benefitsData[${index}][subheading]`, section.benefitSubheading);
        formData.append(`benefitsData[${index}][icon_alt_text]`, section.iconAltText);
        if (section.iconFile) {
          formData.append(`icon_path`, section.iconFile); // Each file with unique key
        }
      });
    }


    // Log FormData contents
    for (const [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    try {
      let response;
      if(isUpdateForm === false){
      response = await fetch(`${baseURL}/sectionTwo`, {
        method: 'POST', // Change to PUT if backend expects PUT
        body: formData,
      });
    }
    else{
      response = await fetch(`${baseURL}/sectionTwo/${taskId}`, {
        method: 'PUT', // Change to PUT if backend expects PUT
        body: formData,
      });
    }


      if (response.ok) {
        alert('Section saved successfully');
        console.log('Section saved successfully');
        // Reset form and states
        setBenefitHeading('');
        setHeading('');
        setBenefitSubheading('');
        setSubheading('');
        setIconFile(null);
        setIconAltText('');
        setSections([]);
      } else {
        console.error('Error saving section:', response.statusText);
      }
    } catch (error) {
      console.error('Error saving section:', error);
    } finally {
      setIsProcessing(false);
      setIsSaving(false);
    }
  };



  return (
    <div>
      <div className="faq-answer-content">
        <form style={{ width: "98%, margin: auto" }} onSubmit={handleSaveSection} onClick={(e) => e.stopPropagation()}>
          <div className="form-group" onClick={(e) => e.stopPropagation()}>
            <label htmlFor="Heading">Heading</label>
            <div
              className="styled-input-field"
              style={{
                width: "1101px",
                height: "50px",
              }}
            >
              <input
                type="text"
                placeholder="Meta Title"
                className="input-text"
                value={heading}
                onChange={(e) => setHeading(e.target.value)}
              />
              <div className="word-count-input">0/60</div>
            </div>
          </div>

          <div className="form-group" onClick={(e) => e.stopPropagation()}>
            <label htmlFor="subHeading">Subheadline</label>
            <div
              className="styled-input-field"
              style={{
                width: "1101px",
                height: "50px",
              }}
            >
              <input
                type="text"
                id="subHeading"
                placeholder="Enter Subheadline"
                className="input-text"
                value={subheading}
                onChange={(e) => setSubheading(e.target.value)}
              />
              <div className="word-count-input">0/60</div>
            </div>
          </div>

          {sections.map((section, index) => (
            <div key={section.id}>
              <div
                className="form-group row"
                onClick={(e) => e.stopPropagation()}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "1px solid #e7e8e9",
                  width: "1064px",
                  marginLeft: "4px",
                  borderRadius: "10px",
                  padding: "10px",
                }}
              >
                <div>
                  <p className="font-weight-bold small" style={{ color: 'rgba(203, 0, 100, 1)' }}>
                    Benefit {section.benefitNumber}
                  </p>
                  <p className="font-weight-bold p">{section.benefitHeading}</p>
                </div>
                <div>
                  <img
                    src={deleteIcon}
                    alt="Delete Icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteSection(section.id);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            </div>
          ))}

          {sections.length <= 5 && (
            <>
              <div
                className="d-flex justify-content-between align-items-center col-sm-12 mt-2"
                style={{ width: "1050px", height: "30px" }}
              >
                <div
                  className="d-flex justify-content-between align-items-center col-sm-12"
                  style={{ width: "1100px", height: "30px" }}
                >
                  <span className="font-weight-bold">
                    Benefits({sections.length + 1}/6)
                  </span>
                </div>
                <div className="d-flex mr-2">
                  <img
                    src={deleteIcon}
                    alt="delete Icon"
                    onClick={(e) => e.stopPropagation()}
                  />
                  <img
                    src={plus}
                    alt="add Icon"
                    onClick={(e) => {
                      addSection();
                      e.stopPropagation();
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>

              <div
                className="form-group row p-3"
                onClick={(e) => e.stopPropagation()}
              >
                <label className="col-sm-1 col-form-label">Heading</label>
                <div
                  className="styled-input-field col-sm-11"
                  style={{ width: "1101px", height: "50px" }}
                >
                  <input
                    type="text"
                    placeholder="Enter Heading"
                    className="input-text"
                    value={benefitHeading}
                    onChange={(e) => setBenefitHeading(e.target.value)}
                  />
                  <div className="word-count-input-half">0/30</div>
                </div>
              </div>
              <div
                className="form-group p-3 row"
                onClick={(e) => e.stopPropagation()}
              >
                <label className="col-sm-1 col-form-label">Subheadline</label>
                <div
                  className="styled-input-field col-sm-11"
                  style={{ width: "1101px", height: "50px" }}
                >
                  <input
                    type="text"
                    placeholder="Enter Subheadline"
                    className="input-text"
                    value={benefitSubheading}
                    onChange={(e) => setBenefitSubheading(e.target.value)}
                  />
                  <div className="word-count-input-half">0/90</div>
                </div>
              </div>
              <div
                className="form-group p-3 row"
                onClick={(e) => e.stopPropagation()}
              >
                <label className="col-sm-1 col-form-label">Icon</label>
                <div
                  className="styled-input-field col-sm-11"
                  style={{ width: "1101px", height: "50px" }}
                >
                  <input
                    type="file"
                    placeholder="Choice Icon"
                    className="input-text"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                  />
                </div>
              </div>
              <div
                className="form-group p-3 row"
                onClick={(e) => e.stopPropagation()}
              >
                <label className="col-sm-1 col-form-label">Icon Alt Text</label>
                <div
                  className="styled-input-field col-sm-11"
                  style={{ width: "1101px", height: "50px" }}
                >
                  <input
                    type="text"
                    placeholder="Enter Icon Alt Text"
                    className="input-text"
                    value={iconAltText}
                    onChange={(e) => setIconAltText(e.target.value)}
                  />
                </div>
              </div>
            </>
          )}
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          <div
            className="p-3"
            style={{
              width: "151px",
              height: "40px",
              marginLeft: "20px",
              borderRadius: "5px",
              background: "Linear-gradient(90deg, #9300B8 0%, #CB0064 100%)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button type="submit"
              style={{
                color: "#FFF",
                fontSize: "17px",
                fontWeight: "700",
                lineHeight: "normal",
                border: "none",
                background: "transparent",
              }}
            //disabled={isSaving}
            >
              Save Section
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default SectionTwo



