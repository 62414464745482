import React from 'react';
import { useParams } from 'react-router-dom';

const FeaturePage = () => {
    const { id } = useParams(); 
  return (
    <div>
        <h1>Feature Details</h1>
      <p>ID: {id}</p>
      <h1>This is the feature page</h1>
    </div>
  );
};

export default FeaturePage;