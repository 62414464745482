import React, { useState , useEffect} from "react";
import deleteIcon from "../../../images/trash-2.svg";
import plus from "../../../images/plus3.svg";
import rightArrow from "../../../images/rightArrow.png";
import axios from "axios";
import { baseURL } from '../../../config/config';

const Reviews = ({setCompleted, taskId}) => {

  const [sections, setSections] = useState([]);
  const [heading, setHeading] = useState('');
  const [subheading, setSubheading] = useState('');
  const [review, setReview] = useState('');
  const [reviewerName, setReviewerName] = useState('');
  const [reviewerOccupation, setReviewerOccupation] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [updated, setUpdated] = useState(false);
  const [isUpdateForm, setIsUpdateForm] = useState(false);

  useEffect(() => {
    if (taskId) {
      console.log("ParamId", taskId);
      axios.get(`${baseURL}/reviews/${taskId}`)
        .then(response => {
          console.log("response.data", response.data);
          const data = response.data;
  
          // Check if the response object has any keys (indicating it has content)
          setCompleted(Object.keys(data).length > 0);
          setIsUpdateForm(Object.keys(data).length > 0);
  
        })
        .catch(error => {
          console.error('Error fetching record:', error);
        });
    }
  }, [taskId]);  

  useEffect(() => {

    
    console.log(`Fields updated`, updated);


  }, [updated, reviewerOccupation]);

  useEffect(() => {

    console.log('sections', sections)
    console.log(`legthd`, sections.length);


  }, [sections]);

  const addSection = () => {
    setUpdated((prev) => !prev);
    if (review.trim() === '' || reviewerName.trim() === '' || reviewerOccupation.trim() === '') {
      setErrorMessage('Please fill in all fields.');
      return;
    }
    const newSection = {
      id: Date.now(),
      review,
      reviewerName,
      reviewerOccupation,
    };

    setSections((prevSections) => {
      // Check if the new section already exists to prevent duplicates
      const sectionExists = prevSections.some((section) =>
        section.review === newSection.review &&
        section.reviewerName === newSection.reviewerName &&
        section.reviewerOccupation === newSection.reviewerOccupation
      );

      if (sectionExists) return prevSections;

      return [...prevSections, newSection];
    });

    // Clear form fields
    setReview('');
    setReviewerName('');
    setReviewerOccupation('');
  


  };


  const deleteSection = (id) => {
    setSections(sections.filter((section) => section.id !== id));
  };

  const handleSaveSection = async (event) => {
    event.preventDefault();
    if (sections.length < 3) {
      setErrorMessage('Reviews must contain at least 3 items.');
      return;
    }

    // If validation passes, clear the error message and proceed with form submission
    setErrorMessage('');

    await new Promise((resolve) => setTimeout(resolve, 100));

    const serviceUrl = sessionStorage.getItem('servicePageUrl');
    const serviceId = sessionStorage.getItem('serviceId');

    const data = {
      heading: heading,
      subheading: subheading,
      slug: serviceUrl,
      service_id: serviceId,
      singleReviews: sections.map(section => ({
        review: section.review,
        reviewer_name: section.reviewerName,
        reviewer_occupation: section.reviewerOccupation,
      })),
    };

    const jsonData = JSON.stringify(data);

    // Log FormData contents
    Object.entries(data).forEach(([key, value]) => {
      console.log(`${key}: ${JSON.stringify(value)}`);
    });

    try {
      let response;
      if(isUpdateForm === false){
      response = await fetch(`${baseURL}/reviews`, {
        method: 'POST', // Change to PUT if backend expects PUT
        headers: {
          'Content-Type': 'application/json',
        },
        body: jsonData,
      });
    }
    else{
      response = await fetch(`${baseURL}/reviews/${taskId}`, {
        method: 'PUT', // Change to PUT if backend expects PUT
        headers: {
          'Content-Type': 'application/json',
        },
        body: jsonData,
      });
    }
      if (response.ok) {
        alert('Section saved successfully');
        console.log('Section saved successfully');
        // Reset form and states
        setReview('');
        setReviewerName('');
        setReviewerOccupation();
        setHeading('');
        setSubheading('');
        setSections([]);
      } else {
        console.error('Error saving section:', response.statusText);
      }
    } catch (error) {
      console.error('Error saving section:', error);
    } finally {
      // setIsProcessing(false);
      // setIsSaving(false);
    }
  };


  return (
    <div>
      <div className="faq-answer-content">
        <form onSubmit={(e) => e.preventDefault()} style={{ width: "98%, margin: auto" }} onClick={(e) => e.stopPropagation()}>
          <div className="form-group" onClick={(e) => e.stopPropagation()}>
            <label htmlFor="Heading">Heading</label>
            <div
              className="styled-input-field"
              style={{
                width: "1101px",
                height: "50px",
              }}
            >
              <input
                type="text"
                placeholder="Enter Heading"
                className="input-text"
                value={heading}
                onChange={(e) => setHeading(e.target.value)}
                maxLength={60}
              />
              <div className="word-count-input">0/60</div>
            </div>
          </div>
          <div className="form-group" onClick={(e) => e.stopPropagation()}>
            <label htmlFor="Heading">Subheading</label>
            <div
              className="styled-input-field"
              style={{
                width: "1101px",
                height: "50px",
              }}
            >
              <input
                type="text"
                placeholder="Enter Subheading"
                className="input-text"
                value={subheading}
                onChange={(e) => setSubheading(e.target.value)}
                maxLength={60}
              />
              <div className="word-count-input">0/60</div>
            </div>
          </div>
          {sections.map((section, index) => (
            <div key={section.id}>
              <div
                className="form-group row"
                onClick={(e) => e.stopPropagation()}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "1px solid #e7e8e9",
                  width: "1064px",
                  marginLeft: "4px",
                  borderRadius: "10px",
                  padding: "10px",
                }}
              >
                <div>
                  <p className="font-weight-bold small" style={{ color: 'rgba(203, 0, 100, 1)' }}>
                    Review {index+1}
                  </p>
                  {/* <p className="font-weight-bold p">{section.benefitHeading}</p> */}
                </div>
                <div>
                  <img
                    src={deleteIcon}
                    alt="Delete Icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteSection(section.id);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            </div>
          ))}

          {sections.length <= 2 && (
            <>
              <div
                className="d-flex justify-content-between align-items-center col-sm-12 mt-2"
                style={{ width: "1050px", height: "30px" }}
              >
                <div
                  className="d-flex justify-content-between align-items-center col-sm-12 "
                  style={{ width: "1100px", height: "30px" }}
                >
                  <span className="font-weight-bold">
                    Customer Reviews({sections.length+1}/3)
                  </span>
                </div>
                <div className="d-flex mr-2">
                  <img
                    src={deleteIcon}
                    alt="delete Icon"
                    onClick={(e) => e.stopPropagation()}
                  />
                  <img
                    src={plus}
                    alt="add Icon"
                    onClick={(e) => {
                      addSection();
                      e.stopPropagation();
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>

              <div
                className="form-group row p-3"
                onClick={(e) => e.stopPropagation()}
              >
                <label className="col-sm-1 col-form-label">Review</label>
                <div
                  className="styled-input-field col-sm-11"
                  style={{
                    width: "1101px",
                    height: "50px",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Enter Review"
                    className="input-text"
                    value={review}
                    onChange={(e) => setReview(e.target.value)}
                    maxLength={360}
                  />
                  <div className="word-count-input-half">0/360</div>
                </div>
              </div>
              <div
                className="form-group row p-3"
                onClick={(e) => e.stopPropagation()}
              >
                <label className="col-sm-1 col-form-label">Reviewer Name</label>
                <div
                  className="styled-input-field col-sm-11"
                  style={{
                    width: "1101px",
                    height: "50px",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Enter Reviewer Name"
                    className="input-text"
                    value={reviewerName}
                    onChange={(e) => setReviewerName(e.target.value)}
                    maxLength={20}
                  />
                  <div className="word-count-input-half">0/20</div>
                </div>
              </div>

              <div
                className="form-group p-3 row"
                onClick={(e) => e.stopPropagation()}
              >
                <label className="col-sm-1 col-form-label">
                  Reviewer Occupation
                </label>
                <div
                  className="styled-input-field col-sm-11"
                  style={{
                    width: "1101px",
                    height: "50px",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Enter Reviewer Occupation"
                    className="input-text"
                    value={reviewerOccupation}
                    onChange={(e) => setReviewerOccupation(e.target.value)}
                    maxLength={30}
                  />
                  <div className="word-count-input-half">0/30</div>
                </div>
              </div>
            </>
          )}
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          <div
            className="p-3"
            style={{
              width: "151px",
              height: "40px",
              marginLeft: "20px",
              borderRadius: "5px",
              background: "Linear-gradient(90deg, #9300B8 0%, #CB0064 100%)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              onClick={handleSaveSection}
              style={{
                color: "#FFF",
                fontSize: "17px",
                fontWeight: "700",
                lineHeight: "normal",
                border: "none",
                background: "transparent",
              }}
            >
              Save Section
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Reviews
