import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { baseURL } from '../../../config/config';

const NonFeaturesForm = ({ setCompleted, taskId }) => {
  const [heading, setHeading] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [iconAltText, setIconAltText] = useState('');
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isUpdateForm, setIsUpdateForm] = useState(false);
 

  useEffect(() => {
    if (taskId) {
      console.log("ParamId", taskId);
      axios.get(`${baseURL}/getNonFeatured-by-service/${taskId}`)
        .then(response => {
          console.log("response.data", response.data);
          const data = response.data;

          // Check if the response object has any keys (indicating it has content)
          setCompleted(Object.keys(data).length > 0);
          setIsUpdateForm(Object.keys(data).length > 0);

        })
        .catch(error => {
          console.error('Error fetching record:', error);
        });
    }
  }, [taskId]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const resetFields = () => {
    setHeading('');
    setDescription('');
    setPrice('');
    setIconAltText('');
    setFile(null);
  };

  const handleSaveNonFeatured = async (event) => {
    event.preventDefault();
    if ((!heading || !description || !price || !iconAltText || !file)) {
      setErrorMessage('All fields are required.');
      return;
    }

    setErrorMessage('');

    const serviceUrl = sessionStorage.getItem('servicePageUrl');
    const serviceId = sessionStorage.getItem('serviceId');
    const service_snippet_id = sessionStorage.getItem('service_snippet_id');

    const formData = new FormData();
    formData.append('service_snippet_id', service_snippet_id);
    formData.append('service_id', serviceId);
    formData.append('slug', serviceUrl);
    formData.append("heading", heading);
    formData.append("description", description);
    formData.append("price", price);
    formData.append("icon_alt_text", iconAltText);
 
    if (file) {
      formData.append("icon", file);
    }

    try {
      let response;
      if(isUpdateForm === false){
      response = await axios.post(`${baseURL}/saveNonFeatured`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    }else{
      response = await axios.put(`${baseURL}/updateNonFeatured/${taskId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    }
      console.log("Non-featured section saved:", response.data);
      alert("Non-featured section saved");
      resetFields();
    } catch (error) {
      console.error("Error saving non-featured section:", error.response ? error.response.data : error.message);
      setError(error.response ? error.response.data.error : error.message);
    }
  };

  return (
    <div>
      {error && <div className="alert alert-danger">{error}</div>}
      <form onSubmit={handleSaveNonFeatured} style={{ width: "98%", margin: "auto" }} onClick={(e) => e.stopPropagation()}>
        <div className="p-3">
          <h3 className="font-weight-bold">Non-Featured</h3>
        </div>
        <div className="form-group row p-3">
          <label className="col-sm-1 col-form-label">Heading</label>
          <div className="styled-input-field col-sm-11">
            <input
              type="text"
              name="heading"
              placeholder="Enter Heading"
              className="input-text"
              value={heading}
              onChange={(e) => setHeading(e.target.value)}
            />
          </div>
        </div>
        <div className="form-group p-3 row">
          <label className="col-sm-1 col-form-label">Description</label>
          <div className="styled-input-field col-sm-11">
            <input
              type="text"
              name="description"
              placeholder="Enter Description"
              className="input-text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>
        <div className="form-group p-3 row">
          <label className="col-sm-1 col-form-label">Price</label>
          <div className="col-sm-11 abc d-flex align-items-center">
            <div className="input-group mb-2 p-0 m-0" style={{ width: "100%" }}>
              <div className="input-group-prepend">
                <div className="input-group-text">$</div>
              </div>
              <input
                type="text"
                name="price"
                className="form-control"
                placeholder="Enter Price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="form-group p-3 row">
          <label className="col-sm-1 col-form-label">Icon</label>
          <div className="col-sm-11">
            <input
              type="file"
              name="icon"
              className="form-control-file"
              onChange={handleFileChange}
            />
          </div>
        </div>
        <div className="form-group p-3 row">
          <label className="col-sm-1 col-form-label">Icon Alt Text</label>
          <div className="styled-input-field col-sm-11">
            <input
              type="text"
              name="icon_alt_text"
              placeholder="Enter Icon Alt Text"
              className="input-text"
              value={iconAltText}
              onChange={(e) => setIconAltText(e.target.value)}
            />
          </div>
        </div>
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        <div className="p-3">
          <button
            type="submit"
            style={{
              color: "#FFF",
              fontSize: "17px",
              fontWeight: "700",
              lineHeight: "normal",
              border: "none",
              background: "transparent",
              padding: "10px 20px",
              borderRadius: "5px",
              background: "linear-gradient(90deg, #9300B8 0%, #CB0064 100%)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Save Section
          </button>
        </div>
      </form>
    </div>
  );
};

export default NonFeaturesForm;
