import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from "./components/Header/Header";
import Login from "./components/Pages/LoginPage/Login";
import EnterMetas from "./components/Pages/ServicePageOne/EnterMetas";
import ServicePageOne from "./components/Pages/ServicePageOne/ServicePageOne";
import StepTwo from "./components/Pages/ServicePageOne/StepTwo";
import ThirdStep from "./components/Pages/ServicePageOne/ThirdStep";
import OurServicesPage from "./components/Pages/ServicePageOne/OurServicesPage";
import Register from "./components/Pages/Register/Register";
import "./App.css";
import FeaturePage from "./components/Pages/Features/Features";

function App() {
  const [currentPage, setCurrentPage] = useState("register"); 

  const handleAddNewPage = () => {
    setCurrentPage("enterMetas");
  };

  const handleNextStep = () => {
    setCurrentPage("stepTwo");
  };

  const handleThirdStep = () => {
    setCurrentPage("thirdStep");
  };

  const handleNextStepOurServices = () => {
    setCurrentPage("ourServicesPage");
  };

  const handleBackToStepTwo = () => {
    setCurrentPage("stepTwo");
  };

  const handleBackToEnterMetas = () => {
    setCurrentPage("enterMetas");
  };

  const handleBackToServicePageOne = () => {
    setCurrentPage("servicePageOne");
  };

  const handleLoginSuccess = () => {
    setCurrentPage("servicePageOne");
  };

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login onLoginSuccess={handleLoginSuccess} />} />
          <Route path="/" element={<Register />} />

          {/* Routes with Header */}
          <Route 
            path="/servicePageOne" 
            element={
              <>
                <Header onAddNewPage={handleAddNewPage} />
                <ServicePageOne />
              </>
            } 
          />
          <Route 
            path="/enterMetas" 
            element={
              <>
                <Header onAddNewPage={handleAddNewPage} />
                <EnterMetas
                  onNext={handleNextStep}
                />
              </>
            } 
          />
          <Route 
            path="/stepTwo" 
            element={
              <>
                <Header onAddNewPage={handleAddNewPage} />
                <StepTwo
                  onBack={handleBackToEnterMetas}
                  onNext={handleThirdStep}
                />
              </>
            } 
          />
          <Route 
            path="/thirdStep" 
            element={
              <>
                <Header onAddNewPage={handleAddNewPage} />
                <ThirdStep
                  onBack={handleBackToStepTwo}
                  onNext={handleNextStepOurServices}
                />
              </>
            } 
          />
          <Route 
            path="/ourServicesPage" 
            element={
              <>
                <Header onAddNewPage={handleAddNewPage} />
                <OurServicesPage onBack={handleBackToStepTwo} />
              </>
            } 
          />
           <Route path="/viewFeatured/:id" element={<FeaturePage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;




