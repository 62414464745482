import React, { useState, useEffect } from "react";
import arrowleft from "../../../images/arrow-left.png";
import rightArrow from "../../../images/rightArrow.png";
import "./EnterMetas.css";
import { useNavigate, useLocation } from "react-router-dom";
import { baseURL } from '../../../config/config';
import axios from 'axios';

const EnterMetas = ({ onNext }) => {
  const [servicePageUrl, setServicePageUrl] = useState("");
  const [title, setTitle] = useState("");
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const taskId = searchParams.get('taskId');
  //const isUpdateForm = !! taskId;


  const [isUpdateForm, setIsUpdateForm] = useState(false);

  useEffect(() => {
    if (taskId) {
      console.log("ParamId", taskId);
      
      axios.get(`${baseURL}/metas-by-id/${taskId}`)
        .then(response => {
          const data = response.data;

          if (data && data.length > 0 && data[0].title && data[0].service_page_url) {
            // Set the form fields with the data
            setTitle(data[0].title);
            setServicePageUrl(data[0].service_page_url);

            // Store the serviceId in session storage
            sessionStorage.setItem('serviceId', taskId);

            // Set `isUpdateForm` to true since we have valid data
            setIsUpdateForm(true);
          } 
          console.log('update or not', isUpdateForm);
        })
        
        .catch(error => {
          console.error('Error fetching record:', error);
          console.log('update or not', isUpdateForm);
        });
    } else {
      // If taskId does not exist, ensure `isUpdateForm` is false
      setIsUpdateForm(false);
    }
    console.log('update or not', isUpdateForm);
  }, [taskId]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!servicePageUrl || !title) {
      setErrorMessage('All fields are required.');
      return;
    }

    setErrorMessage('');


    try {
      let response;
      if(isUpdateForm === false){
      response = await fetch(`${baseURL}/enterMetas`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          service_page_url: servicePageUrl,
          title: title,
        }),
      });
    }
    else
    {
      response = await fetch(`${baseURL}/enterMetas/${taskId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          service_page_url: servicePageUrl,
          title: title,
        }),
      });
    }
      const data = await response.json();
      console.log("Meta entry saved:", data);

      sessionStorage.setItem('servicePageUrl', servicePageUrl);
      if(isUpdateForm === false){
      sessionStorage.setItem('serviceId', data.id);
      }
      setServicePageUrl("");
      setTitle("");
if(response.ok){
      isUpdateForm === true?navigate(`/stepTwo?taskId=${taskId}`):navigate(`/stepTwo`);
}
      onNext(); // Call parent function to proceed to the next step
    } catch (error) {
      if (error.response) {
        // Handle server-side errors
        if (error.response.status === 409) {
          setErrorMessage('This URL already exists. Please use a different URL.');
        } else {
          setErrorMessage('An error occurred while saving the entry. Please try again.');
        }
      } else {
        // Handle network or other errors
        setErrorMessage('An unexpected error occurred. Please check your network and try again.');
      }
    }
  };

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <div className="progress-indicator">
          <div className="progress-bar filled-bar">
            <div className="progress-bar semi-filled-bar"></div>
          </div>
          <div className="progress-bar unfilled-bar"></div>
          <div className="progress-bar unfilled-bar"></div>
        </div>

        <div className="body-data">
          <div className="d-flex flex-column body-data-child">
            <div
              className="back-btn-parent"
              onClick={() => window.history.back()}
            >
              <img src={arrowleft} alt="" className="back-img" />
              <span className="back-text">Back</span>
            </div>
            <div className="steps-text mb-4" style={{ width: "430px" }}>
              <span>Step 1: Enter Service Page URL</span>
            </div>
            <div className="styled-input-field">
              <input
                type="text"
                placeholder="Enter Title (H1)"
                className="input-text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="styled-input-with-line">
              <input
                type="text"
                placeholder="www.alldesignsstudio.com/"
                className="input-text input-text-link"
                value={servicePageUrl}
                onChange={(e) => setServicePageUrl(e.target.value)}
              />
            </div>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            <div className="styled-button">
              <div className="gradient-background">
                <button
                  type="submit"
                  className=""
                  style={{
                    background: "transparent",
                    border: "none",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <span className="button-text" style={{ color: "white" }}>
                    Next
                  </span>
                  <img src={rightArrow} alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EnterMetas;
