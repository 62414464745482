import React, { useState, useEffect } from "react";
import arrowleft from "../../../images/arrow-left.png";
import rightArrow from "../../../images/rightArrow.png";
import axios from "axios";
import "./StepTwo.css";
import { useNavigate, useLocation } from "react-router-dom";
import { baseURL } from '../../../config/config';


const StepTwo = ({ onBack, onNext }) => {
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const taskId = searchParams.get('taskId');
  const [isUpdateForm, setIsUpdateForm] = useState(false);


  useEffect(() => {
    if (taskId) {
      console.log("ParamId", taskId);
      axios.get(`${baseURL}/step-two/${taskId}`)
        .then(response => {
          console.log("response.data", response.data);
          const data = response.data;

          if (data && data.length > 0 && data[0].meta_title && data[0].meta_description) {

          setMetaTitle(data[0].meta_title);
          setMetaDescription(data[0].meta_description);
          setIsUpdateForm(true);
          }

        })
        .catch(error => {
          console.error('Error fetching record:', error);
        });
    }
  }, [taskId]);


  const handleNext = async () => {
    try {
      if (!metaTitle || !metaDescription) {
        setErrorMessage('All fields are required.');
        return;
      }

      setErrorMessage('');

      const serviceUrl = sessionStorage.getItem('servicePageUrl');
      const serviceId = sessionStorage.getItem('serviceId');

      let response;
      if(isUpdateForm === false){
        response = await axios.post(`${baseURL}/step-two`, {
          meta_title: metaTitle,
          meta_description: metaDescription,
          slug:serviceUrl,
          service_id:serviceId
        });
  
    }
    else
    {
      response = await axios.put(`${baseURL}/step-two/${taskId}`, {
        meta_title: metaTitle,
        meta_description: metaDescription,
        slug:serviceUrl,
      
      });

    }

      if (response.status === 200) {
        console.log("Meta entry saved:", response.data);
        isUpdateForm === true?navigate(`/thirdStep?taskId=${taskId}`):navigate(`/thirdStep`);
        onNext(); // Call parent function to proceed to the next step
      } else {
        throw new Error("Failed to save meta entry");
      }
    } catch (error) {
      console.error("Error saving meta entry:", error);
      // Handle error as needed (show error message, retry logic, etc.)
    }
  };

  const handleBack = () => {
    onBack();
  };

  // Function to count words in the text
  const countWords = (text) => {
    const words = text.trim().split(/\s+/);
    return words.length;
  };

  const handleMetaTitleChange = (e) => {
    setMetaTitle(e.target.value);
  };

  const handleMetaDescriptionChange = (e) => {
    setMetaDescription(e.target.value);
  };

  return (
    <div className="container">
      <div className="progress-indicator">
        <div className="d-flex justify-content-center align-items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
          >
            <circle cx="9.5" cy="9.5" r="9.5" fill="#00CE84" />
            <path
              d="M13.366 5.78223L7.88906 11.2652L5.63384 9.02942L4.13037 10.5199L7.88906 14.2995L14.8695 7.32596L13.366 5.78223Z"
              fill="white"
            />
          </svg>
          <div
            className="progress-bar filled-bar"
            style={{ marginLeft: "-5px" }}
          >
            <div className="progress-bar filled-bar"></div>
          </div>
        </div>
        <div className="progress-bar semi-filled-bar"></div>
        <div className="progress-bar unfilled-bar"></div>
      </div>

      <div className="body-data">
        <div className="d-flex flex-column body-data-child">
          <div className="back-btn-parent" onClick={() => window.history.back()}>
            <img src={arrowleft} alt="" className="back-img" />
            <span className="back-text">Back</span>
          </div>
          <div className="steps-text-heading mb-4" style={{ width: "280px" }}>
            <span>Step 2: Enter Metas</span>
          </div>
          <div className="styled-input-field">
            <input
              type="text"
              placeholder="Meta Title"
              className="input-text"
              value={metaTitle}
              onChange={handleMetaTitleChange}
            />
            <div className="word-count-input">{countWords(metaTitle)} / 60</div>
          </div>
          <div className="text-area-with-word-count">
            <textarea
              className="text-area-input input-text"
              placeholder="Meta Description"
              value={metaDescription}
              onChange={handleMetaDescriptionChange}
            />
            <div className="word-count">
              {countWords(metaDescription)} / 160
            </div>
          </div>
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          <div className="btnParents">
            <div className="button-container" style={{ width: "190px" }}>
              <button className="custom-button">Save & Continue Later</button>
            </div>
            <div className="styled-button" onClick={handleNext}>
              <div className="gradient-background">
                <div className="button-content">
                  <span className="button-text text-white">Next</span>
                  <img src={rightArrow} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepTwo;
