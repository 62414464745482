import React, { useEffect, useState } from "react";
import deleteIcon from "../../../images/trash-2.svg";
import plus from "../../../images/plus3.svg";
import axios from 'axios';
import { baseURL } from '../../../config/config';

const SeoContent = ({setCompleted, taskId}) => {

  const [sections, setSections] = useState([]);
  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");
  const [errorMessage, setErrorMessage] = useState('');
  const [isUpdateForm, setIsUpdateForm] = useState(false);

  useEffect(() => {
    if (taskId) {
      console.log("ParamId", taskId);
      axios.get(`${baseURL}/seoContent/${taskId}`)
        .then(response => {
          console.log("response.data", response.data);
          const data = response.data;
  
          // Check if the response object has any keys (indicating it has content)
          setCompleted(data.length > 0);
          setIsUpdateForm(data.length > 0);
  
        })
        .catch(error => {
          console.error('Error fetching record:', error);
        });
    }
  }, [taskId]);  


  useEffect(() => {

    console.log('sections', sections)
    console.log(`legthd`, sections.length);


  }, [sections]);

  const addSection = () => {
    if ((!heading || !description)) {
      setErrorMessage('All fields are required.');
      return;
    }

    const newSection = {
      id: Date.now(),
      heading,
      description,
    };

    setSections((prevSections) => {
      // Check if the new section already exists to prevent duplicates
      const sectionExists = prevSections.some((section) =>
        section.heading === newSection.heading &&
        section.description === newSection.description
      );
      if (sectionExists) return prevSections;

      return [...prevSections, newSection];
    });

    // Clear form fields

    setHeading('');
    setDescription('');

  };


  const deleteSection = (id) => {
    setSections(sections.filter((section) => section.id !== id));
  };

  const handleSaveSection = async (event) => {
    event.preventDefault();
    if ((sections.length < 1)) {
      setErrorMessage('Atleast one item required.');
      return;
    }
    setErrorMessage('');
    await new Promise((resolve) => setTimeout(resolve, 100));
    const serviceUrl = sessionStorage.getItem('servicePageUrl');
    const serviceId = sessionStorage.getItem('serviceId');
    const data = {
      slug: serviceUrl,
      service_id: serviceId,
      sections: sections.map(section => ({
        heading: section.heading,
        description: section.description,

      })),
    };

    const jsonData = JSON.stringify(data);

    // Log FormData contents
    Object.entries(data).forEach(([key, value]) => {
      console.log(`${key}: ${JSON.stringify(value)}`);
    });

    try {
      let response;
      if(isUpdateForm === false){
       response = await fetch(`${baseURL}/seoContent`, {
        method: 'POST', // Change to PUT if backend expects PUT
        headers: {
          'Content-Type': 'application/json',
        },
        body: jsonData,
      });
    }
    else{
      response = await fetch(`${baseURL}/seoContent/${taskId}`, {
        method: 'PUT', // Change to PUT if backend expects PUT
        headers: {
          'Content-Type': 'application/json',
        },
        body: jsonData,
      });
    }
    
      if (response.ok) {
        alert('Section saved successfully');
        console.log('Section saved successfully');
        // Reset form and states

        setHeading('');
        setDescription('');
        setSections([]);

      } else {
        console.log('Error saving section:', response.statusText);
      }
    } catch (error) {
      console.log('Error saving section:', error.message);
  
      // Log stack trace if available
      if (error.stack) {
        console.log('Stack trace:', error.stack);
      }
      
      // Log error details (if any)
      if (error.response) {
        console.log('Response status:', error.response.status);
        console.log('Response data:', error.response.data);
      }
    } finally {
      // setIsProcessing(false);
      // setIsSaving(false);
    }
  };
  return (
    <div>
      <div className="faq-answer-content">
        <form style={{ width: "98%, margin: auto" }} onSubmit={handleSaveSection} onClick={(e) => e.stopPropagation()}>

          {sections.map((section, index) => (
            <div key={section.id}>
              <div
                className="form-group row"
                onClick={(e) => e.stopPropagation()}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "1px solid #e7e8e9",
                  width: "1064px",
                  marginLeft: "4px",
                  borderRadius: "10px",
                  padding: "10px",
                }}
              >
                <div>
                  <p className="font-weight-bold small" style={{ color: 'rgba(203, 0, 100, 1)' }}>
                    Section {index + 1}
                  </p>

                </div>
                <div>
                  <img
                    src={deleteIcon}
                    alt="Delete Icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteSection(section.id);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            </div>
          ))}

          {sections.length <= 3 && (
            <>

              <div
                className="d-flex justify-content-between align-items-center col-sm-12 mt-2"
                style={{ width: "1050px", height: "30px" }}
              >
                <div
                  className="d-flex justify-content-between align-items-center col-sm-12 "
                  style={{ width: "1100px", height: "30px" }}
                >
                  <span className="font-weight-bold">
                    Section
                  </span>
                </div>
                <div className="d-flex mr-2">
                  <img
                    src={deleteIcon}
                    alt="delete Icon"
                    onClick={(e) => e.stopPropagation()}
                  />
                  <img
                    src={plus}
                    alt="add Icon"
                    onClick={(e) => {
                      addSection();
                      e.stopPropagation();
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>

              <div
                className="form-group row p-3"
                onClick={(e) => e.stopPropagation()}
              >
                <label className="col-sm-1 col-form-label">Heading</label>
                <div
                  className="styled-input-field col-sm-11"
                  style={{
                    width: "1101px",
                    height: "50px",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Enter Heading"
                    className="input-text"
                    value={heading}
                    onChange={(e) => setHeading(e.target.value)}
                    maxLength={360}
                  />
                  <div className="word-count-input-half">0/360</div>
                </div>
              </div>
              <div
                className="form-group p-3 row "
                onClick={(e) => e.stopPropagation()}
              >
                <label htmlFor="exampleFormControlInput1 col-sm-1">
                  Description
                </label>

                <div
                  className="styled-input-field col-sm-11 "
                  style={{
                    width: "1101px",
                    height: "auto",
                  }}
                >
                  <textarea
                    placeholder="Enter Description"
                    className="input-text"
                    rows={6}
                    style={{ height: "60px" }}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                  <div className="word-count-input-half"></div>
                </div>
              </div>
            </>
          )}
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          <div
            className="p-3"
            style={{
              width: "151px",
              height: "40px",
              marginLeft: "20px",
              borderRadius: "5px",
              background: "Linear-gradient(90deg, #9300B8 0%, #CB0064 100%)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              type="submit"
              style={{
                color: "#FFF",
                fontSize: "17px",
                fontWeight: "700",
                lineHeight: "normal",
                border: "none",
                background: "transparent",
              }}
            >
              Save Section
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default SeoContent
